body {
  margin: 0;
  font-family: Roboto, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, sans-serif;
}

.box-load {
  margin: 15% auto;
  width: 80%;
}

.box {
  width: 2.5em;
  height: 3em;
  border: 3px solid transparent;
  border-top-color: #fc2f70;
  border-bottom-color: #fc2f70;
  border-radius: 50%;
  animation: spin-stretch 2s ease infinite;
}

@keyframes spin-stretch {
  50% {
    transform: rotate(360deg) scale(0.4, 0.33);
    border-width: 8px;
  }

  100% {
    transform: rotate(720deg) scale(1, 1);
    border-width: 3px;
  }
}

.menu-mb {
  transform: none !important;
  transition: transform 0.5s ease !important;
}

.modal-full {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1051;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.input-error {
  border-color: red !important;
}

.nav-tabs .nav-item .nav-link .title {
  font-weight: 700;
  font-size: 1.222rem;
  line-height: 1.556rem;
  text-transform: uppercase;
}

.nav-tabs .nav-item .nav-link {
  color: #a10023;
  padding: 2rem;
}

.carousel-list-overflow-inherit {
  overflow: inherit !important;
}

.react-multi-carousel-track {
  cursor: grab;
}

.btn-edit {
  border: 0px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0 15px;
  margin-left: 5px;
  background-color: #26b026;
  color: #fff;
}

.terra_radio_label_active {
  border-top: 3px solid #ff0037 !important;
}

.terra_radio_label {
  margin-top: -2px !important;
  z-index: 1;
}

.terra_radio_label:hover {
  border-top: 3px solid #ff0037;
}

.ml-15px {
  margin-left: 15px;
}

.m04__content a {
  color: #fff !important;
}

.wrapperClassName {
  height: 200px;
  display: inline-block;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100% !important;
}

.jodit-status-bar-link,
.jodit-add-new-line {
  display: none !important;
}

.jodit-add-new-line:after {
  background-color: transparent !important;
}

.btn-x-remove {
  border: 0px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0 15px;
  margin-left: 5px;
  background-color: #7b877b;
  color: #fff;
}

.btn-edit-list {
  border: 0px;
  font-weight: 400;
  color: #fff;
  background-color: #26b026;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 0.8rem;
}

.btn-delete-list {
  border: 0px;
  font-weight: 400;
  color: #fff;
  background-color: #e00a38;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 0.8rem;
}

.select-image-bg {
  width: 100%;
  height: 100%;
  display: inherit;
}

.select-image-div-1 {
  width: 10%;
  display: inline-block;
  padding: 5px;
}

.select-image-div-3 {
  width: 10%;
  display: inline-block;
  padding: 5px;
}

.bg-select-image-div-2 {
  display: inherit;
}

.selected-image-gb {
  border: 1px solid #b07726;
}

.bg-select-image-div-2:hover {
  border: 1px solid #b07726;
}

.text-decoration-none {
  text-decoration: none;
}

.class_servies {
  overflow: hidden;
}

.btn-top {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 99;
  font-size: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  background: #fff;
  padding: 0;
  /* line-height: 42px; */
  transition: 0.5s ease-out;
  border-radius: 4px;
  width: 2.667rem;
  height: 2.667rem;
}

.gfield_label {
  color: #fff;
}

.arrow-up-btn-top {
  padding: 0px 17px;
  color: red;
}

.picker-dialog {
  z-index: 2000 !important;
}

.btn-top-label {
  font-size: 0.5rem;
  line-height: 0.9rem;
  letter-spacing: 1.5px;
  font-weight: 600;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

::-webkit-scrollbar {
  width: 0em;
  height: 0em;
}
