body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--gradient--gradient-1: linear-gradient(
    135deg,
    #00ffff 0%,
    #ff1493 100%
  );
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--font-size--heading-1: 102px;
  --wp--preset--font-size--heading-2: 60px;
  --wp--preset--font-size--heading-3: 56px;
  --wp--preset--font-size--heading-4: 36px;
  --wp--preset--font-size--heading-5: 32px;
  --wp--preset--font-size--heading-6: 30px;
  --wp--preset--font-size--heading-small: 22px;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}
.ccc-disable-scroll {
  overflow-y: scroll;
  position: fixed;
}
#ccc {
  font-size: 16px;
  line-height: 1.4em;
  position: fixed;
  z-index: 2147483647;
}
#ccc-icon {
  background: transparent;
  border: 0;
  bottom: 0;
  cursor: pointer;
  height: 80px;
  padding: 0;
  position: fixed;
  width: 80px;
  z-index: 20;
}
#ccc-icon.ccc-icon--left {
  left: 0;
}
#ccc-icon.ccc-icon--right {
  right: 0;
}
#ccc-icon.ccc-icon--dark {
  fill: #000025;
}
#ccc-icon.ccc-icon--dark #star path {
  fill: #fff;
}
#ccc-icon.ccc-icon--light {
  fill: #555;
}
#ccc-icon.ccc-icon--light #star path {
  fill: #f4f4f4;
}
#ccc-overlay {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  opacity: 1;
  perspective: 0;
  position: fixed;
  right: 0;
  top: 0;
  will-change: opacity;
  z-index: 1;
}
#ccc-module {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 2;
}
#ccc-module.ccc-module--slideout {
  bottom: 0;
  max-width: 520px;
  top: 0;
  width: 90%;
}
#ccc-module.ccc-module--slideout.ccc-module--left {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slide-right;
  animation-name: slide-right;
  left: 0;
}
#ccc-module.ccc-module--slideout.ccc-module--right {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slide-left;
  animation-name: slide-left;
  right: 0;
}
#ccc-content {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  padding: 24px;
  position: absolute;
  width: 100%;
}
.ccc-content--dark {
  fill: #fff;
  background-color: #313147;
  color: #fff;
}
.ccc-content--light {
  fill: #333;
  background-color: #f4f4f4;
  color: #333;
}
#ccc-close {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: 0 !important;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
}
#ccc-title,
.ccc-title {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.5em;
  margin: 0;
  padding-right: 32px;
  text-align: left;
}
#ccc #ccc-recommended-settings {
  clear: both;
  float: none;
  margin-left: 0;
}
#ccc h2,
#ccc h3 {
  font-weight: 700;
  margin: 0;
  padding-right: 120px;
}
#ccc h2,
#ccc h3,
#ccc p,
.ccc-intro {
  font-size: 1em;
  line-height: 1.5em;
  text-align: left;
}
#ccc p,
.ccc-intro {
  font-weight: 400;
  margin: 1em 0;
}
#ccc hr {
  border: 0;
  height: 1px;
  margin: 24px 0;
  opacity: 0.25;
}
#ccc .ccc-svg-element {
  display: inline-block;
  height: 16px;
  margin-left: 8px;
  position: relative;
  top: 6px;
  width: 16px;
}
#ccc .ccc-content--dark hr {
  background-color: #fff;
}
#ccc .ccc-content--dark a,
#ccc .ccc-content--dark h1,
#ccc .ccc-content--dark h2,
#ccc .ccc-content--dark h3,
#ccc .ccc-content--dark p,
#ccc .ccc-content--dark span {
  fill: #fff;
  color: #fff;
}
#ccc .ccc-content--light hr {
  background-color: #333;
}
#ccc .ccc-content--light a,
#ccc .ccc-content--light h1,
#ccc .ccc-content--light h2,
#ccc .ccc-content--light h3,
#ccc .ccc-content--light p,
#ccc .ccc-content--light span {
  fill: #333;
  color: #333;
}
#ccc .optional-cookie {
  position: relative;
}
.checkbox-toggle {
  margin: 0;
  position: absolute;
  right: 0;
  top: -3px;
}
#ccc .checkbox-toggle-input {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
#ccc .checkbox-toggle--checkbox {
  border: 2px solid #fff;
  border-radius: 4px;
}
#ccc .checkbox-toggle--checkbox .checkbox-toggle-label {
  display: block;
  height: 24px;
  width: 24px;
}
#ccc .checkbox-toggle--checkbox .checkbox-toggle-off,
#ccc .checkbox-toggle--checkbox .checkbox-toggle-on {
  display: none;
}
#ccc .checkbox-toggle--checkbox .checkbox-toggle-toggle {
  border-radius: 4px;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}
#ccc .checkbox-toggle--checkbox input:checked ~ .checkbox-toggle-toggle:after {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  content: "";
  height: 10px;
  left: 9px;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  width: 5px;
}
#ccc .checkbox-toggle--slider {
  border: 4px solid #fff;
  border-radius: 50px;
}
#ccc .checkbox-toggle--slider .checkbox-toggle-label {
  cursor: pointer;
  display: flex;
  margin: 0;
  z-index: 1;
}
#ccc .checkbox-toggle--slider .checkbox-toggle-off,
#ccc .checkbox-toggle--slider .checkbox-toggle-on {
  flex: 1;
  flex-basis: 50%;
  font-size: 0.75em;
  font-weight: 700;
  min-width: 40px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  width: 50%;
  z-index: 2;
}
#ccc .checkbox-toggle--slider .checkbox-toggle-off {
  opacity: 0.65;
}
#ccc .checkbox-toggle--slider .checkbox-toggle-toggle {
  background-color: #000;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.4s ease;
  width: 50%;
  z-index: 3;
}
#ccc .checkbox-toggle--slider input:checked ~ .checkbox-toggle-toggle {
  left: 50%;
}
#ccc .checkbox-toggle--slider.checkbox-toggle--dark {
  background-color: #111125;
  border-color: #111125;
}
#ccc .checkbox-toggle--checkbox.checkbox-toggle--dark {
  background-color: #111125;
  border-color: #fff;
}
#ccc .checkbox-toggle--dark .checkbox-toggle-toggle {
  background-color: #2f2f5f;
}
#ccc .checkbox-toggle--light {
  background-color: #555;
  border-color: #555;
}
#ccc .checkbox-toggle--light .checkbox-toggle-toggle {
  background-color: #000;
}
#ccc .checkbox-toggle--light .checkbox-toggle-on,
#ccc .checkbox-toggle--slider.checkbox-toggle--light .checkbox-toggle-off {
  color: #fff;
}
#ccc .checkbox-toggle--slider input:checked ~ .checkbox-toggle-toggle {
  background-color: #fff;
}
#ccc .ccc-alert {
  border-radius: 8px;
  margin: 24px 0;
  padding: 16px;
}
#ccc .ccc-alert-title {
  align-items: center;
  display: flex;
  padding: 0;
}
#ccc .ccc-alert-icon {
  display: inline-block;
  flex-shrink: 0;
  height: 30px;
  margin: 0 8px 0 0;
  width: 30px;
}
#ccc .ccc-alert-break {
  margin: 12px 0;
}
.third-party-cookie-link {
  align-items: center;
  display: flex;
  margin: 16px 0;
}
.third-party-cookie-name {
  font-size: 1em;
  font-weight: 700;
}
.third-party-cookie-icon {
  display: inline-block;
  height: 16px;
  margin-left: 16px;
  width: 16px;
}
#ccc .ccc-content--dark .ccc-alert {
  fill: #fff;
  background: #111125;
  color: #fff;
}
#ccc .ccc-content--dark .third-party-cookie-link {
  background: #111125;
  color: #fff;
}
#ccc .ccc-content--light .ccc-alert,
#ccc .ccc-content--light .third-party-cookie-link {
  background: #eaeaea;
}
#ccc .ccc-module--popup {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slide-up;
  animation-name: slide-up;
  bottom: 0;
  left: 0;
  opacity: 1;
  right: 0;
  top: 0;
}
#ccc .ccc-module--popup #ccc-content {
  border-radius: 12px;
  height: 90%;
  left: 50%;
  max-height: 600px;
  max-width: 600px;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
#ccc .ccc-notify-box-dismiss {
  display: none !important;
}
@media (min-width: 600px) {
  #ccc #ccc-notify.ccc-notify__box {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-name: box-slide-up;
    animation-name: box-slide-up;
    border-radius: 12px;
    box-sizing: border-box;
    flex-direction: column;
    height: 90%;
    justify-content: space-around;
    left: 50%;
    max-height: 240px;
    max-width: 480px;
    opacity: 1;
    padding: 32px 64px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
  #ccc .ccc-notify-box-dismiss {
    display: block !important;
    position: absolute !important;
    right: 16px !important;
    top: 16px !important;
  }
}
#ccc #ccc-notify {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slide-up;
  animation-name: slide-up;
  border-radius: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  left: 0;
  margin: 0;
  min-height: 48px;
  opacity: 1;
  overflow: hidden;
  padding: 16px;
  position: fixed;
  right: 0;
  z-index: 30;
}
.ccc-notify-text h1 {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
  margin: 0;
  padding-right: 120px;
  text-align: left;
}
#ccc #ccc-notify.ccc-notify__top {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: slide-down;
  animation-name: slide-down;
  bottom: auto;
  top: 0;
}
#ccc #ccc-notify h3 {
  padding: 0;
}
#ccc #ccc-notify p {
  font-size: 0.9em;
  margin: 0;
  opacity: 0.8;
}
#ccc .ccc-notify-buttons {
  margin-top: 16px;
  padding-right: 24px;
  position: relative;
}
#ccc .ccc-notify-button {
  background: transparent;
  border: 2px solid;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  margin: 4px 8px 16px 0;
  padding: 8px 16px !important;
}
#ccc #ccc-notify-dismiss {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  height: 24px;
  padding: 0 !important;
  position: absolute;
  right: 0;
  top: -4px;
  width: 24px;
}
@media (min-width: 400px) {
  #ccc #ccc-notify-dismiss {
    position: relative;
    right: auto;
  }
  #ccc .ccc-notify-buttons {
    padding-right: 0;
  }
}
@media (min-width: 1020px) {
  #ccc #ccc-notify {
    align-items: center;
    flex-direction: row;
  }
  #ccc .ccc-notify-text {
    margin-right: 16px;
  }
  #ccc .ccc-notify-buttons {
    flex-shrink: 0;
    white-space: nowrap;
  }
}
@media (min-width: 1200px) {
  #ccc #ccc-notify {
    font-size: 16px;
    padding: 16px 100px;
    z-index: 10;
  }
}
#ccc .ccc-content--dark #ccc-notify,
#ccc .ccc-content--dark #ccc-notify span {
  fill: #fff;
  background-color: #313147;
  color: #fff;
}
#ccc .ccc-content--dark .ccc-notify-button,
#ccc .ccc-content--dark .ccc-notify-button span {
  border-color: #fff;
  color: #fff;
}
#ccc .ccc-content--dark .ccc-button-solid,
#ccc .ccc-content--dark .ccc-button-solid span {
  background: #fff;
  color: #111125;
  font-weight: 700;
}
.ccc-notify-link {
  border: 0 !important;
  text-decoration: underline;
}
#ccc .ccc-content--light #ccc-notify,
#ccc .ccc-content--light #ccc-notify span {
  fill: #000;
  background: #f4f4f4;
  color: #000;
}
#ccc .ccc-content--light .ccc-notify-button,
#ccc .ccc-content--light .ccc-notify-button span {
  border-color: #000;
  color: #000;
}
#ccc .ccc-content--light .ccc-button-solid,
#ccc .ccc-content--light .ccc-button-solid span {
  background: #000;
  color: #fff;
  font-weight: 700;
}
.ccc-info-link {
  font-size: 0.8em;
  font-weight: 700;
  text-decoration: none;
}
.ccc-info-link .svg-element {
  height: 14px;
  margin-left: 6px;
  width: 14px;
}
#ccc .ccc-module--slideout .ccc-info {
  height: 100px;
}
#ccc[popup] .ccc-info {
  height: 40px;
}
@media print {
  #ccc {
    display: none !important;
  }
}
#ccc-close-label {
  display: block;
  font-size: 16px;
}
#ccc .ccc-panel--indent #ccc-title {
  padding-right: 180px;
}
#ccc-close.ccc-close--labelled {
  padding: 8px !important;
  right: 0;
  top: 0;
}
#ccc-content.ccc-content--light #ccc-close.ccc-close--labelled {
  background-color: #eaeaea;
}
#ccc-content.ccc-content--dark #ccc-close.ccc-close--labelled {
  background-color: #111125;
}
#ccc-end {
  margin: 16px 0 32px;
}
#ccc-dismiss-button {
  display: block;
}
#ccc .ccc-content--highlight .ccc-link.ccc-tabbable:focus {
  filter: invert(100%);
  padding: 8px;
}
#ccc .ccc-content--highlight.ccc-content--light .ccc-link.ccc-tabbable:focus {
  background-color: #eaeaea !important;
  border-color: #eaeaea !important;
}
#ccc .ccc-content--highlight.ccc-content--dark .ccc-link.ccc-tabbable:focus {
  background-color: #111125 !important;
  border-color: #111125 !important;
}
#ccc .ccc-content--highlight #ccc-close:focus,
#ccc .ccc-content--highlight .ccc-button-solid:focus,
#ccc
  .ccc-content--highlight
  .checkbox-toggle-input:focus
  ~ .checkbox-toggle-toggle,
#ccc .ccc-content--highlight .third-party-cookie-link:focus {
  filter: invert(100%);
}
#ccc .ccc-content--highlight.ccc-content--light .ccc-tabbable:focus {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 100%, 0.2),
    0 6px 20px 0 hsla(0, 0%, 100%, 0.2);
}
#ccc .ccc-content--highlight.ccc-content--dark .ccc-tabbable:focus {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
#ccc .checkbox-toggle:focus-within {
  outline: auto;
}
#ccc #ccc-icon.ccc-icon--no-outline,
#ccc .ccc-content--no-outline *,
#ccc .ccc-content--no-outline .ccc-tabbable {
  outline: none;
}
#ccc .ccc-content--highlight .ccc-tabbable:active,
#ccc .ccc-content--highlight .ccc-tabbable:hover,
#ccc .ccc-icon--highlight:active,
#ccc .ccc-icon--highlight:hover {
  box-shadow: none !important;
  filter: none !important;
}
.ccc-panel {
  left: 24px;
  position: absolute;
  right: 24px;
  top: 24px;
}
.ccc-panel.hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.ccc-panel.visible {
  height: auto;
  opacity: 1;
  transition: opacity 0.4s;
}
#ccc .iab-vendor,
#ccc .optional-vendor {
  margin: 0 0 8px;
}
#ccc .optional-vendor {
  font-size: 0.875em;
}
#ccc .optional-vendor-heading {
  font-weight: 700;
  padding-left: 24px;
  text-decoration: underline;
}
#ccc .ccc-vendor-reveal {
  align-items: center;
  background: transparent;
  border: 1px dotted;
  display: flex;
  font-size: 0.875em;
  opacity: 0.5;
  transition: opacity 0.4s;
}
#ccc .ccc-vendor-reveal:focus,
#ccc .ccc-vendor-reveal:hover {
  opacity: 1;
}
#ccc .ccc-legal-description {
  margin-top: 1rem;
}
#ccc .ccc-vendor-reveal svg {
  height: 16px;
  margin-left: 8px;
  width: 16px;
}
#ccc .optional-vendor-warning {
  align-items: center;
  display: flex;
}
#ccc .optional-vendor-warning svg {
  height: 16px;
  margin-right: 8px;
  width: 16px;
}
#ccc .optional-vendor-content {
  padding-left: 24px;
}
#ccc .iab-vendor-heading {
  font-size: 0.875em;
  font-weight: 400;
  text-decoration: underline;
}
#ccc .cmp-button-holder {
  display: flex;
  font-size: 0.75em;
  justify-content: space-between;
}
#ccc .cmp-back {
  opacity: 0.5;
  transition: opacity 0.4s;
}
#ccc .cmp-back:hover {
  opacity: 1;
}
.ccc-sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes slide-right {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slide-right {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@-webkit-keyframes slide-up {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slide-up {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@-webkit-keyframes box-slide-up {
  0% {
    transform: translate3d(-50%, 100%, 0);
  }
  to {
    transform: translate3d(-50%, -50%, 0);
  }
}
@keyframes box-slide-up {
  0% {
    transform: translate3d(-50%, 100%, 0);
  }
  to {
    transform: translate3d(-50%, -50%, 0);
  }
}
@-webkit-keyframes slide-down {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slide-down {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translateZ(0);
  }
}
#ccc .iab-object {
  height: 48px;
  position: relative;
  width: 98%;
}
#ccc .iab-object.iab-object--vendor {
  width: 85%;
}
#ccc .iab-object .checkbox-toggle {
  left: 0;
  right: auto;
  top: 4px;
}
#ccc .iab-object .checkbox-toggle-label {
  height: 18px;
  width: 18px;
}
#ccc
  .iab-object
  .checkbox-toggle--checkbox
  input:checked
  ~ .checkbox-toggle-toggle:after {
  border-width: 0 2px 2px 0;
  height: 8px;
  left: 7px;
  top: 3px;
  width: 3px;
}
#ccc .iab-object-label {
  display: block;
  font-size: 0.75em;
  margin-left: 32px;
}
#ccc .vendor-details {
  font-size: 0.75rem;
  margin-bottom: 16px;
}
#ccc .vendor-details h4 {
  margin: 0.5rem 0;
}
#ccc .ccc-title.ccc-title--iab {
  margin-bottom: 1rem;
}
#ccc .iab-header-toggle {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}
#ccc .iab-header-toggle .ccc-title {
  margin: 0 0 0 16px;
}
#ccc .iab-header-toggle .ccc-vendor-reveal svg {
  margin: 0;
}
#ccc .iab-vendor-toggle .checkbox-toggle {
  top: auto;
}
#ccc .iab-vendor-toggle h3 {
  padding-right: 180px;
}
#ccc .iab-disclosure-load {
  align-items: center;
  display: flex;
}
#ccc .iab-disclosure-load button {
  margin-right: 12px;
}
#ccc .iab-disclosure-load a {
  text-decoration: none;
}
#ccc .iab-disclosure-load a > span {
  position: relative;
  top: -2px;
}
#ccc .iab-disclosure-load a > .ccc-svg-element {
  top: 4px;
}
#ccc .iab-disclosure-table {
  border-collapse: collapse;
  margin: 2em 0;
  width: 100%;
}
#ccc .iab-disclosure-table td,
#ccc .iab-disclosure-table th {
  border: 1px solid;
}
#ccc .iab-disclosure-table thead td {
  border: none;
}
#ccc .ccc-content--dark td,
#ccc .ccc-content--dark th {
  border-color: #fff;
}
#ccc .iab-disclosure-table dl {
  display: grid;
  grid-template: auto/1fr 1fr;
  width: 150px;
}
#ccc .iab-disclosure-table ul {
  list-style: none;
}
#ccc .iab-disclosure-table dl,
#ccc .iab-disclosure-table ul {
  margin: 0;
  padding: 0;
}
#ccc .iab-disclosure-table dd,
#ccc .iab-disclosure-table dt,
#ccc .iab-disclosure-table li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0.3em 0.5em;
}
#ccc .ccc-content--dark .iab-disclosure-table dd,
#ccc .ccc-content--dark .iab-disclosure-table dt,
#ccc .ccc-content--dark .iab-disclosure-table li {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}
#ccc .ccc-content--dark .iab-disclosure-table li:last-child {
  border: none;
}
#ccc .iab-disclosure-table td,
#ccc .iab-disclosure-table th {
  padding: 1em;
  vertical-align: top;
}
#ccc .iab-disclosure-table tr:nth-child(2n) {
  background: rgba(0, 0, 0, 0.1);
}
#ccc .ccc-content--highlight .ccc-tabbable:hover {
  outline: auto;
}

#ccc,
#ccc button {
  font-family: roboto, sans-serif !important;
}
#ccc,
#ccc #ccc-content,
#ccc #ccc-notify,
#ccc h2,
#ccc h3,
#ccc p,
#ccc p span,
#ccc a,
#ccc a span,
#ccc-content button,
#ccc-content button span,
#ccc-content span {
  color: #fff !important;
  fill: #fff !important;
}
#ccc hr {
  background-color: #fff !important;
}
#ccc #ccc-content,
#ccc #ccc-notify {
  background-color: #ff0037 !important;
}
#ccc .checkbox-toggle-on,
#ccc .checkbox-toggle-off {
  color: #ff0037 !important;
}
#ccc .checkbox-toggle-toggle {
  background-color: #ff0037 !important;
}
#ccc .checkbox-toggle {
  background-color: #fff !important;
  border-color: #fff !important;
}
