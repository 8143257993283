@charset "UTF-8";
/*!
Theme Name: Luna
Theme URI: https://www.93digital.co.uk
Author: 93Devs
Author URI: https://www.93digital.co.uk
Description: 93Digital's Luna Theme
Version: 2.0.0
License: GNU General Public License v2 or later
License URI: https://www.gnu.org/licenses/gpl-2.0.html
Text Domain: luna
Tags: Luna, 93digital, 93devs, starter-theme, boilerplate

This theme, like WordPress, is licensed under the GPL.

93Digital's development starter theme, the boilerplate for all our WordPress projects.
*/
body {
  --primary-color: #ff0037;
  --primary-white: #fff;
}

body.gaming-theme {
  --primary-color: #ca00ff;
}
*,
:after,
:before {
  box-sizing: border-box;
}
:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 100%;
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion) {
  html {
    scroll-behavior: auto;
  }
}

.gfield_description {
  color: hsla(0, 0%, 100%, 0.95);
}

.padding-description {
  padding: 0rem 9% 0rem;
}

.form-container .container {
  width: 90%;
  max-width: 66rem !important;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}
hr {
  height: 0;
  color: inherit;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: Roboto, sans-serif;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.g03__copyright {
  line-height: 1.625;
  font-weight: 400;
  font-size: 0.9rem;
}

sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
}
button,
input,
optgroup,
select,
textarea {
  font-family: Roboto, sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  appearance: button;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
figure {
  margin: 0;
}
[tabindex]:not([tabindex="-1"]),
a,
button,
input,
select,
textarea {
  cursor: pointer;
}
[tabindex]:not([tabindex="-1"]):focus,
a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}
body.remove-focus [tabindex]:not([tabindex="-1"]):focus,
body.remove-focus a:focus,
body.remove-focus button:focus,
body.remove-focus input:focus,
body.remove-focus select:focus,
body.remove-focus textarea:focus {
  outline: none !important;
}
/* @media (min-width: 1200px) {
  html {
    font-size: 1vw;
  }
} */
* {
  scroll-margin-top: 4rem;
}
@media (min-width: 1240px) {
  * {
    scroll-margin-top: 6.5rem;
  }
}
body {
  font-family: Roboto, sans-serif;
  position: relative;
  color: #151416;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.45;
  font-size: 1rem;
}
/* @media (min-width: 1200px) {
  body {
    font-size: 1.25rem;
  }
} */
::selection {
  color: #fff;
  background-color: #000;
}
a:not([class]) {
  color: var(--primary-color);
}
.mobile-only {
  display: block !important;
}
@media (min-width: 810px) {
  .mobile-only {
    display: none !important;
  }
}
.gfield.hidden_label > label,
.gfield_consent .gfield_required,
.hidden_label.archive-container__filter-wrap > label,
.hidden_label .gfield_label,
.hidden_label.m18__filter-wrap > label,
.screen-reader-text,
.skip-link {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute !important;
  word-wrap: normal !important;
}
.gfield.hidden_label > label:focus,
.gfield_consent .gfield_required:focus,
.hidden_label.archive-container__filter-wrap > label:focus,
.hidden_label .gfield_label:focus,
.hidden_label.m18__filter-wrap > label:focus,
.screen-reader-text:focus,
.skip-link:focus {
  background-color: #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: inherit;
  font-weight: 700;
  height: auto;
  width: auto;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  left: 5px;
  z-index: 100000;
}
.skip-link:focus {
  outline: 0 !important;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  to {
    transform: translateY(0);
  }
}
svg {
  width: auto;
  /* fill: currentColor; */
}
img,
svg {
  display: block;
  height: auto;
}
img {
  max-width: 100%;
}
img.lazy {
  opacity: 0;
}
@media (min-width: 810px) {
  img:not(.initial) {
    transition: opacity 1s;
  }
}
img.error,
img.initial,
img.loaded {
  opacity: 1;
}
img:not([src]) {
  visibility: hidden;
}
.object-fit {
  width: 100%;
  height: 100%;
}
.object-fit--cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.object-fit--contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.object-fit--fill {
  -o-object-fit: fill;
  object-fit: fill;
}
.object-fit--scale-down {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}
.object-position--center-top {
  -o-object-position: center top;
  object-position: center top;
}
.object-position--center-bottom {
  -o-object-position: center bottom;
  object-position: center bottom;
}
.object-position--top-left {
  -o-object-position: top left;
  object-position: top left;
}
.object-position--top-right {
  -o-object-position: top right;
  object-position: top right;
}
.object-position--bottom-left {
  -o-object-position: bottom left;
  object-position: bottom left;
}
.object-position--bottom-right {
  -o-object-position: bottom right;
  object-position: bottom right;
}
iframe {
  max-width: 100%;
}
.is-draggable {
  cursor: move;
  cursor: grab;
}
.is-draggable .is-grabbing {
  cursor: grabbing;
}
.gform_ajax_spinner,
.spinner {
  width: 1.111rem;
  height: 1.111rem;
  border: 0.167rem solid var(--primary-color);
  border-right: 0.167rem solid transparent;
  border-radius: 50%;
  animation: rotate 0.8s linear infinite;
}
.container,
.m08__quote,
.m16--default .m16__wrapper {
  width: 90%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 66rem;
}
.container--large {
  max-width: 70.556rem;
}
.container .container,
.container .m08__quote,
.container .m16--default .m16__wrapper,
.m08__quote .container,
.m08__quote .m08__quote,
.m08__quote .m16--default .m16__wrapper,
.m16--default .container .m16__wrapper,
.m16--default .m08__quote .m16__wrapper,
.m16--default .m16__wrapper .container,
.m16--default .m16__wrapper .m08__quote,
.m16--default .m16__wrapper .m16__wrapper {
  width: 100%;
  max-width: none;
}
.entry-content {
  width: 90%;
  max-width: 43.333rem;
  margin: 0 auto;
}
.entry-content > .m14:first-child {
  margin-top: 0;
}
@media (min-width: 810px) {
  .entry-content > .m14:first-child {
    margin-top: 3.85em;
  }
}
.entry-content > p:empty {
  display: none;
}
.break-out,
figure.wp-block-image.alignfull,
figure.wp-block-video.alignfull {
  position: relative;
  margin-left: -50vw !important;
  max-width: 100vw !important;
  width: 100vw !important;
  left: 50% !important;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .break-out,
  figure.wp-block-image.alignfull,
  figure.wp-block-video.alignfull {
    max-width: calc(100vw - 9px) !important;
  }
}
.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  display: block;
  position: relative;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  /* word-wrap: break-word; */
}
@media (min-width: 1280px) {
  .heading,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}
.heading:empty,
h1:empty,
h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty {
  display: none;
}
.has-heading-1-font-size,
.heading--1,
h1 {
  font-weight: 900;
  font-style: italic;
  line-height: 1.02;
  font-size: 2.778rem;
  font-size: 10vw;
}
@media (min-width: 810px) {
  .has-heading-1-font-size,
  .heading--1,
  h1 {
    font-size: 4.667rem;
  }
}

.has-heading-2-font-size,
.heading--2,
h2 {
  line-height: 1.1;
  font-size: 2.333rem;
  font-size: 8vw;
}
@media (min-width: 810px) {
  .has-heading-2-font-size,
  .heading--2,
  h2 {
    font-size: 3.333rem;
  }
}
.has-heading-3-font-size,
.heading--3,
h3 {
  line-height: 1.12;
  font-size: 2.111rem;
  font-size: 7vw;
}
@media (min-width: 810px) {
  .has-heading-3-font-size,
  .heading--3,
  h3 {
    font-size: 3.111rem;
  }
}
.has-heading-4-font-size,
.heading--4,
.single-takeout-item__heading,
h4 {
  line-height: 1.12;
  font-size: 6vw;
}
@media (min-width: 810px) {
  .has-heading-4-font-size,
  .heading--4,
  .single-takeout-item__heading,
  h4 {
    line-height: 2.556rem;
    font-size: 2rem;
  }
}
.has-heading-5-font-size,
.heading--5,
h5 {
  font-size: 1.278rem;
  line-height: 1.42;
}
@media (min-width: 810px) {
  .has-heading-5-font-size,
  .heading--5,
  h5 {
    font-size: 1.778rem;
  }
}
.has-heading-6-font-size,
.heading--6,
h6 {
  font-size: 1.111rem;
  line-height: 1;
}
@media (min-width: 810px) {
  .has-heading-6-font-size,
  .heading--6,
  h6 {
    font-size: 1.667rem;
  }
}
.g03 .gform_title,
.has-heading-small-font-size,
.heading--small {
  font-size: 1.222rem;
  line-height: 1.556rem;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.lowercase {
  text-transform: none;
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}
.font-weight--100 {
  font-weight: 100;
}
.font-weight--200 {
  font-weight: 200;
}
.font-weight--300 {
  font-weight: 300;
}
.font-weight--400 {
  font-weight: 400;
}
.font-weight--500 {
  font-weight: 500;
}
.font-weight--600 {
  font-weight: 600;
}
.font-weight--700 {
  font-weight: 700;
}
.font-weight--800 {
  font-weight: 800;
}
.font-weight--900 {
  font-weight: 900;
}
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
}
.gfield_visibility_hidden {
  display: none !important;
}
.gform_confirmation_message,
.gform_submission_error {
  text-transform: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  margin: 0 0 2em;
}
.gform_wrapper {
  display: flex;
  flex-direction: column;
}
.gform_fields {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.gform_button svg {
  display: none;
}
.m09 .gform_button svg {
  display: block;
}
.validation_error {
  margin-top: 2em;
  order: 20;
}
.archive-container__filter-wrap,
.gfield,
.m18__filter-wrap {
  margin-bottom: 1em;
  width: 100%;
  display: block !important;
}
@media (min-width: 810px) {
  .archive-container__filter-wrap,
  .gfield,
  .m18__filter-wrap {
    width: calc(50% - 0.95em);
  }
}
.gfield_description {
  font-size: 0.778rem;
}
.m09--white .gfield_description,
.m32 .gfield_description,
.m37 .gfield_description {
  color: hsla(0, 0%, 100%, 0.95);
}
.gfield_error input[type="checkbox"] + label:after,
.gfield_error input[type="checkbox"] + label:before,
.gfield_error input[type="email"],
.gfield_error input[type="number"],
.gfield_error input[type="password"],
.gfield_error input[type="radio"] + label:after,
.gfield_error input[type="radio"] + label:before,
.gfield_error input[type="search"],
.gfield_error input[type="tel"],
.gfield_error input[type="text"],
.gfield_error input[type="url"],
.gfield_error select,
.gfield_error textarea {
  border-color: #000 !important;
}
.m09--white .gfield_error input[type="checkbox"] + label:after,
.m09--white .gfield_error input[type="checkbox"] + label:before,
.m09--white .gfield_error input[type="email"],
.m09--white .gfield_error input[type="number"],
.m09--white .gfield_error input[type="password"],
.m09--white .gfield_error input[type="radio"] + label:after,
.m09--white .gfield_error input[type="radio"] + label:before,
.m09--white .gfield_error input[type="search"],
.m09--white .gfield_error input[type="tel"],
.m09--white .gfield_error input[type="text"],
.m09--white .gfield_error input[type="url"],
.m09--white .gfield_error select,
.m09--white .gfield_error textarea,
.m32 .gfield_error input[type="checkbox"] + label:after,
.m32 .gfield_error input[type="checkbox"] + label:before,
.m32 .gfield_error input[type="email"],
.m32 .gfield_error input[type="number"],
.m32 .gfield_error input[type="password"],
.m32 .gfield_error input[type="radio"] + label:after,
.m32 .gfield_error input[type="radio"] + label:before,
.m32 .gfield_error input[type="search"],
.m32 .gfield_error input[type="tel"],
.m32 .gfield_error input[type="text"],
.m32 .gfield_error input[type="url"],
.m32 .gfield_error select,
.m32 .gfield_error textarea,
.m37 .gfield_error input[type="checkbox"] + label:after,
.m37 .gfield_error input[type="checkbox"] + label:before,
.m37 .gfield_error input[type="email"],
.m37 .gfield_error input[type="number"],
.m37 .gfield_error input[type="password"],
.m37 .gfield_error input[type="radio"] + label:after,
.m37 .gfield_error input[type="radio"] + label:before,
.m37 .gfield_error input[type="search"],
.m37 .gfield_error input[type="tel"],
.m37 .gfield_error input[type="text"],
.m37 .gfield_error input[type="url"],
.m37 .gfield_error select,
.m37 .gfield_error textarea {
  color: hsla(0, 0%, 100%, 0.95);
}
.gfield_error .validation_message {
  color: #000;
  font-size: 0.778rem;
}
.m09--white .gfield_error .validation_message,
.m32 .gfield_error .validation_message,
.m37 .gfield_error .validation_message {
  color: hsla(0, 0%, 100%, 0.95);
}
.archive-container__filter-wrap label,
.gfield label,
.m18__filter-wrap label {
  display: block;
  margin-bottom: 0.1em;
  font-size: 0.889rem;
  font-weight: 700;
}
.gfield_checkbox,
.gfield_consent,
.gfield_html,
.gfield_radio,
.gfield_textarea {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}
.gfield_checkbox,
.gfield_radio {
  list-style: none;
  margin: 0;
  padding: 0;
}
.gfield_checkbox li,
.gfield_radio li {
  position: relative;
}
.gfield_consent label {
  font-size: 0.667rem;
  font-weight: 400;
  line-height: 0.778rem;
}
.gfield_has_conditional[style*="display: none"] label {
  opacity: 0.5;
}
.archive-container__filter-wrap input[type="email"],
.archive-container__filter-wrap input[type="number"],
.archive-container__filter-wrap input[type="password"],
.archive-container__filter-wrap input[type="search"],
.archive-container__filter-wrap input[type="tel"],
.archive-container__filter-wrap input[type="text"],
.archive-container__filter-wrap input[type="url"],
.archive-container__filter-wrap select,
.archive-container__filter-wrap textarea,
.gfield input[type="email"],
.gfield input[type="number"],
.gfield input[type="password"],
.gfield input[type="search"],
.gfield input[type="tel"],
.gfield input[type="text"],
.gfield input[type="url"],
.gfield select,
.gfield textarea,
.m18__filter-wrap input[type="email"],
.m18__filter-wrap input[type="number"],
.m18__filter-wrap input[type="password"],
.m18__filter-wrap input[type="search"],
.m18__filter-wrap input[type="tel"],
.m18__filter-wrap input[type="text"],
.m18__filter-wrap input[type="url"],
.m18__filter-wrap select,
.m18__filter-wrap textarea {
  width: 100%;
  font-size: 1rem;
  border: 0.167rem solid #e0e5e5;
  border-radius: 0;
  background-color: #fff;
  padding: 0.65em 1em;
}
.m09--white .gfield_error .archive-container__filter-wrap input[type="email"],
.m09--white .gfield_error .archive-container__filter-wrap input[type="number"],
.m09--white
  .gfield_error
  .archive-container__filter-wrap
  input[type="password"],
.m09--white .gfield_error .archive-container__filter-wrap input[type="search"],
.m09--white .gfield_error .archive-container__filter-wrap input[type="tel"],
.m09--white .gfield_error .archive-container__filter-wrap input[type="text"],
.m09--white .gfield_error .archive-container__filter-wrap input[type="url"],
.m09--white .gfield_error .archive-container__filter-wrap select,
.m09--white .gfield_error .archive-container__filter-wrap textarea,
.m09--white .gfield_error .gfield input[type="email"],
.m09--white .gfield_error .gfield input[type="number"],
.m09--white .gfield_error .gfield input[type="password"],
.m09--white .gfield_error .gfield input[type="search"],
.m09--white .gfield_error .gfield input[type="tel"],
.m09--white .gfield_error .gfield input[type="text"],
.m09--white .gfield_error .gfield input[type="url"],
.m09--white .gfield_error .gfield select,
.m09--white .gfield_error .gfield textarea,
.m09--white .gfield_error .m18__filter-wrap input[type="email"],
.m09--white .gfield_error .m18__filter-wrap input[type="number"],
.m09--white .gfield_error .m18__filter-wrap input[type="password"],
.m09--white .gfield_error .m18__filter-wrap input[type="search"],
.m09--white .gfield_error .m18__filter-wrap input[type="tel"],
.m09--white .gfield_error .m18__filter-wrap input[type="text"],
.m09--white .gfield_error .m18__filter-wrap input[type="url"],
.m09--white .gfield_error .m18__filter-wrap select,
.m09--white .gfield_error .m18__filter-wrap textarea {
  border-color: rgba(var(--primary-color), 0.5);
}
.archive-container__filter-wrap input[type="email"][disabled],
.archive-container__filter-wrap input[type="number"][disabled],
.archive-container__filter-wrap input[type="password"][disabled],
.archive-container__filter-wrap input[type="search"][disabled],
.archive-container__filter-wrap input[type="tel"][disabled],
.archive-container__filter-wrap input[type="text"][disabled],
.archive-container__filter-wrap input[type="url"][disabled],
.archive-container__filter-wrap select[disabled],
.archive-container__filter-wrap textarea[disabled],
.gfield input[type="email"][disabled],
.gfield input[type="number"][disabled],
.gfield input[type="password"][disabled],
.gfield input[type="search"][disabled],
.gfield input[type="tel"][disabled],
.gfield input[type="text"][disabled],
.gfield input[type="url"][disabled],
.gfield select[disabled],
.gfield textarea[disabled],
.m18__filter-wrap input[type="email"][disabled],
.m18__filter-wrap input[type="number"][disabled],
.m18__filter-wrap input[type="password"][disabled],
.m18__filter-wrap input[type="search"][disabled],
.m18__filter-wrap input[type="tel"][disabled],
.m18__filter-wrap input[type="text"][disabled],
.m18__filter-wrap input[type="url"][disabled],
.m18__filter-wrap select[disabled],
.m18__filter-wrap textarea[disabled] {
  pointer-events: none;
  background-color: #e0e5e5;
}
.archive-container__filter-wrap input[type="email"]:focus,
.archive-container__filter-wrap input[type="number"]:focus,
.archive-container__filter-wrap input[type="password"]:focus,
.archive-container__filter-wrap input[type="search"]:focus,
.archive-container__filter-wrap input[type="tel"]:focus,
.archive-container__filter-wrap input[type="text"]:focus,
.archive-container__filter-wrap input[type="url"]:focus,
.archive-container__filter-wrap select:focus,
.archive-container__filter-wrap textarea:focus,
.gfield input[type="email"]:focus,
.gfield input[type="number"]:focus,
.gfield input[type="password"]:focus,
.gfield input[type="search"]:focus,
.gfield input[type="tel"]:focus,
.gfield input[type="text"]:focus,
.gfield input[type="url"]:focus,
.gfield select:focus,
.gfield textarea:focus,
.m18__filter-wrap input[type="email"]:focus,
.m18__filter-wrap input[type="number"]:focus,
.m18__filter-wrap input[type="password"]:focus,
.m18__filter-wrap input[type="search"]:focus,
.m18__filter-wrap input[type="tel"]:focus,
.m18__filter-wrap input[type="text"]:focus,
.m18__filter-wrap input[type="url"]:focus,
.m18__filter-wrap select:focus,
.m18__filter-wrap textarea:focus {
  border-color: var(--primary-color);
}
.archive-container__filter-wrap input[type="email"].filter-select,
.archive-container__filter-wrap input[type="number"].filter-select,
.archive-container__filter-wrap input[type="password"].filter-select,
.archive-container__filter-wrap input[type="search"].filter-select,
.archive-container__filter-wrap input[type="tel"].filter-select,
.archive-container__filter-wrap input[type="text"].filter-select,
.archive-container__filter-wrap input[type="url"].filter-select,
.archive-container__filter-wrap select.filter-select,
.archive-container__filter-wrap textarea.filter-select,
.gfield input[type="email"].filter-select,
.gfield input[type="number"].filter-select,
.gfield input[type="password"].filter-select,
.gfield input[type="search"].filter-select,
.gfield input[type="tel"].filter-select,
.gfield input[type="text"].filter-select,
.gfield input[type="url"].filter-select,
.gfield select.filter-select,
.gfield textarea.filter-select,
.m18__filter-wrap input[type="email"].filter-select,
.m18__filter-wrap input[type="number"].filter-select,
.m18__filter-wrap input[type="password"].filter-select,
.m18__filter-wrap input[type="search"].filter-select,
.m18__filter-wrap input[type="tel"].filter-select,
.m18__filter-wrap input[type="text"].filter-select,
.m18__filter-wrap input[type="url"].filter-select,
.m18__filter-wrap select.filter-select,
.m18__filter-wrap textarea.filter-select {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: var(--primary-color);
  padding-left: 0.75em;
}
.archive-container__filter-wrap .ginput_container_select,
.gfield .ginput_container_select,
.m18__filter-wrap .ginput_container_select {
  position: relative;
}
.archive-container__filter-wrap .ginput_container_select:after,
.gfield .ginput_container_select:after,
.m18__filter-wrap .ginput_container_select:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 2.667rem;
  height: 100%;
  pointer-events: none;
  background-image: url(assets/svg/ico_select.svg);
  background-repeat: repeat-y;
  background-position: 50%;
}
.archive-container__filter-wrap .ginput_container_consent,
.gfield .ginput_container_consent,
.m18__filter-wrap .ginput_container_consent {
  margin: 0.5em 0 1em;
}
.archive-container__filter-wrap select,
.gfield select,
.m18__filter-wrap select {
  -webkit-appearance: none;
  appearance: none;
  padding-right: calc(2.5rem + 1em);
}
.archive-container__filter-wrap select.filter-select,
.gfield select.filter-select,
.m18__filter-wrap select.filter-select {
  background-image: url(assets/svg/ico_select_simple.svg);
  background-repeat: no-repeat;
  background-position: right 0.75em center;
}
.archive-container__filter-wrap textarea,
.gfield textarea,
.m18__filter-wrap textarea {
  resize: none;
  height: 8rem;
}
.archive-container__filter-wrap input[type="checkbox"],
.archive-container__filter-wrap input[type="radio"],
.gfield input[type="checkbox"],
.gfield input[type="radio"],
.m18__filter-wrap input[type="checkbox"],
.m18__filter-wrap input[type="radio"] {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute !important;
  word-wrap: normal !important;
}
.archive-container__filter-wrap input[type="checkbox"]:focus + label:before,
.archive-container__filter-wrap input[type="radio"]:focus + label:before,
.gfield input[type="checkbox"]:focus + label:before,
.gfield input[type="radio"]:focus + label:before,
.m18__filter-wrap input[type="checkbox"]:focus + label:before,
.m18__filter-wrap input[type="radio"]:focus + label:before {
  border-color: var(--primary-color);
}
.archive-container__filter-wrap input[type="checkbox"]:checked + label:after,
.archive-container__filter-wrap input[type="radio"]:checked + label:after,
.gfield input[type="checkbox"]:checked + label:after,
.gfield input[type="radio"]:checked + label:after,
.m18__filter-wrap input[type="checkbox"]:checked + label:after,
.m18__filter-wrap input[type="radio"]:checked + label:after {
  opacity: 1;
  border-color: var(--primary-color);
}
.archive-container__filter-wrap input[type="checkbox"] + label,
.archive-container__filter-wrap input[type="radio"] + label,
.gfield input[type="checkbox"] + label,
.gfield input[type="radio"] + label,
.m18__filter-wrap input[type="checkbox"] + label,
.m18__filter-wrap input[type="radio"] + label {
  margin: 0 0 0.3em;
  padding: 0.5em 0 0 1.667rem;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.archive-container__filter-wrap input[type="checkbox"] + label:after,
.archive-container__filter-wrap input[type="checkbox"] + label:before,
.archive-container__filter-wrap input[type="radio"] + label:after,
.archive-container__filter-wrap input[type="radio"] + label:before,
.gfield input[type="checkbox"] + label:after,
.gfield input[type="checkbox"] + label:before,
.gfield input[type="radio"] + label:after,
.gfield input[type="radio"] + label:before,
.m18__filter-wrap input[type="checkbox"] + label:after,
.m18__filter-wrap input[type="checkbox"] + label:before,
.m18__filter-wrap input[type="radio"] + label:after,
.m18__filter-wrap input[type="radio"] + label:before {
  content: "";
  width: 1.278rem;
  height: 1.278rem;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  background-color: #fff;
  border: 0.167rem solid #e0e5e5;
  cursor: pointer;
}
.archive-container__filter-wrap input[type="checkbox"] + label:after,
.archive-container__filter-wrap input[type="radio"] + label:after,
.gfield input[type="checkbox"] + label:after,
.gfield input[type="radio"] + label:after,
.m18__filter-wrap input[type="checkbox"] + label:after,
.m18__filter-wrap input[type="radio"] + label:after {
  opacity: 0;
  transition: opacity 0.1s ease;
}
.archive-container__filter-wrap input[type="radio"] + label:after,
.archive-container__filter-wrap input[type="radio"] + label:before,
.gfield input[type="radio"] + label:after,
.gfield input[type="radio"] + label:before,
.m18__filter-wrap input[type="radio"] + label:after,
.m18__filter-wrap input[type="radio"] + label:before {
  border-radius: 100%;
}
.archive-container__filter-wrap input[type="radio"] + label:after,
.gfield input[type="radio"] + label:after,
.m18__filter-wrap input[type="radio"] + label:after {
  box-shadow: inset 0 0 0 0.222rem #fff,
    inset 0 0 0 5.556rem var(--primary-color);
}
.archive-container__filter-wrap input[type="checkbox"] + label:after,
.gfield input[type="checkbox"] + label:after,
.m18__filter-wrap input[type="checkbox"] + label:after {
  content: "✔";
  font-size: 0.833rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151416;
}
.gform_ajax_spinner {
  margin-top: 1em;
}
#gf_form_focus {
  display: none !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
body.gaming-theme
  .archive-container__filter-wrap
  .ginput_container_select:after,
body.gaming-theme .gfield .ginput_container_select:after,
body.gaming-theme .m18__filter-wrap .ginput_container_select:after {
  background-image: url(assets/svg/ico_select_purple.svg);
}
.button,
.wp-block-buttons .wp-block-button__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  background-color: transparent;
  padding: 0.7em 1.3em;
  font-size: 1rem;
  color: var(--primary-color);
  border: 0.222rem solid var(--primary-color);
  border-radius: 0;
  line-height: 1.2;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.25s ease, filter 0.25s ease, opacity 0.25s ease,
    border-color 0.25s ease, background-color 0.25s ease;
}
@media (min-width: 810px) {
  .button,
  .wp-block-buttons .wp-block-button__link {
    padding: 1em 1.75em 0.9em;
    font-size: 1.111rem;
  }
}
.button svg,
.wp-block-buttons .wp-block-button__link svg {
  width: 2.333rem;
  height: 1.444rem;
  margin-left: 0.444rem;
}
.button:focus,
.button:hover,
.wp-block-buttons .wp-block-button__link:focus,
.wp-block-buttons .wp-block-button__link:hover {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.button--white,
.g03 .gform_button,
.g03 input[type="submit"],
.m09--red-full .button,
.m09--white .button,
.m23 .gform_button,
.m37 .gform_button,
.wp-block-buttons .wp-block-button__link--white {
  color: #fff;
  border-color: #fff;
}
.button--white:focus,
.button--white:hover,
.g03 .gform_button:focus,
.g03 .gform_button:hover,
.g03 input[type="submit"]:focus,
.g03 input[type="submit"]:hover,
.m09--red-full .button:focus,
.m09--red-full .button:hover,
.m09--white .button:focus,
.m09--white .button:hover,
.m23 .gform_button:focus,
.m23 .gform_button:hover,
.m37 .gform_button:focus,
.m37 .gform_button:hover,
.wp-block-buttons .wp-block-button__link--white:focus,
.wp-block-buttons .wp-block-button__link--white:hover {
  color: var(--primary-color);
  border-color: #fff;
  background-color: #fff;
}
.button--red,
.wp-block-buttons .wp-block-button__link--red {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.button--red:focus,
.button--red:hover,
.wp-block-buttons .wp-block-button__link--red:focus,
.wp-block-buttons .wp-block-button__link--red:hover {
  color: var(--primary-color);
  border-color: #fff;
  background-color: #fff;
}
.button--no-icon svg,
.button:not(input[type="submit"]):empty,
.wp-block-buttons .wp-block-button__link--no-icon svg,
.wp-block-buttons .wp-block-button__link:not(input[type="submit"]):empty {
  display: none;
}
.breadcrumbs {
  width: 100%;
  display: block;
}
.breadcrumbs a:not([class]) {
  text-decoration: none;
  position: relative;
}
.scroll-down {
  position: absolute;
  color: #fff;
  left: 50%;
  bottom: 1.111rem;
  transform: translateX(-50%);
  font-style: normal;
  font-size: 0.444rem;
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 3;
  z-index: 1;
}
.block-button-brow {
  color: #6c757d4f;
  border-color: #6c757d4f;
}
.block-button-brow:not(:last-child) {
  margin-right: 0.889rem;
}
.block-button-brow:hover {
  color: #6c757d4f;
  border-color: #6c757d4f;
}
.block-button-brow:focus {
  color: #6c757d4f;
  border-color: #6c757d4f;
}
@media (min-width: 768px) {
  .scroll-down {
    bottom: 3.111rem;
    font-size: 0.667rem;
    letter-spacing: 0.125rem;
    line-height: inherit;
  }
}
.scroll-down svg {
  margin: 0 auto;
  width: 0.833rem;
  height: 1.167rem;
}
@media (min-width: 768px) {
  .scroll-down svg {
    width: 1.111rem;
    height: 1.556rem;
  }
}
.luna-video-player {
  position: relative;
}
.luna-video-player:after {
  content: "";
  position: absolute;
  bottom: 1.111rem;
  left: 0.833rem;
  width: 1.111rem;
  height: 1.111rem;
  border: 0.167rem solid #ff0037;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate 0.75s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.luna-video-player.is-waiting:after {
  opacity: 1;
}
.video-play-button {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.6s ease;
}
.is-playing .video-play-button {
  background-color: transparent;
}
.video-play-button__icon {
  pointer-events: none;
  border: 0.222rem solid #fff;
  width: 5.556rem;
  height: 3.556rem;
  padding: 0;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.6s ease;
}
@media (min-width: 768px) {
  .video-play-button__icon {
    width: 10.111rem;
    height: 6.111rem;
  }
}
.video-play-button__icon svg {
  width: 0.889rem;
  height: 1.056rem;
}
@media (min-width: 768px) {
  .video-play-button__icon svg {
    width: 1.389rem;
    height: 1.778rem;
  }
}
.is-playing .video-play-button__icon {
  opacity: 0;
  pointer-events: none;
}
.video-buffer,
.video-progress {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  left: 0;
  bottom: 0;
  border: 0;
  height: 0.278rem;
  order: 1;
  width: calc(100% - 5.667rem);
}
@media (min-width: 768px) {
  .video-buffer,
  .video-progress {
    width: calc(100% - 9.333rem);
  }
}
.video-buffer__value,
.video-progress__value {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  transform-origin: left;
  transform: scaleX(0);
  background-color: var(--primary-color);
}
.video-buffer__value {
  background-color: rgba(var(--primary-color), 0.5);
}
.video-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1;
}
.video-control__button {
  width: 1.889rem;
  height: 1.889rem;
  color: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fff;
  background-color: var(--primary-color);
  padding: 0;
}
@media (min-width: 768px) {
  .video-control__button {
    width: 3.111rem;
    height: 3.111rem;
  }
}
.video-control__button:last-child {
  border-right: 0;
}
.video-control__button:focus {
  position: relative;
  z-index: 1;
}
.is-playing .video-control__icon--play,
.plyr--playing .video-control__icon--play {
  display: none;
}
.video-control__icon--play svg {
  width: 0.833rem;
  height: 1.056rem;
}
.video-control__icon--pause {
  display: none;
}
.is-playing .video-control__icon--pause,
.plyr--playing .video-control__icon--pause {
  display: block;
}
.video-control__icon--pause svg {
  width: 0.889rem;
  height: 1.056rem;
}
.is-muted .video-control__icon--volume {
  display: none;
}
.video-control__icon--volume svg {
  width: 1.333rem;
  height: 1rem;
}
.video-control__icon--muted {
  display: none;
}
.is-muted .video-control__icon--muted {
  display: block;
}
.video-control__icon--muted svg {
  position: relative;
  width: 1.444rem;
  height: 1rem;
  left: 1px;
}
.video-control__icon--fullscreen svg {
  width: 1rem;
  height: 1rem;
}
.tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 0.889rem;
  font-weight: 500;
  padding: 0.3em 0.75em;
}
.tag--featured {
  color: var(--primary-color);
  background-color: #fff;
  border: 2px solid var(--primary-color);
}
.tag__icon {
  width: 0.778rem;
  height: 0.778rem;
  margin-right: 0.5em;
}
.pagination {
  display: flex;
  margin: 3.889rem auto 0;
  align-items: center;
  justify-content: center;
}
.pagination .page-numbers {
  display: flex;
  font-size: 1.556rem;
  height: 3.444rem;
  padding: 0.556rem;
  align-items: center;
  color: var(--primary-color);
  border-color: var(--primary-color);
  border-style: solid;
  border-width: 0.222rem 0.111rem;
  text-decoration: none;
}
@media (min-width: 810px) {
  .pagination .page-numbers {
    padding: 0.833rem;
  }
}
.pagination .page-numbers:first-child {
  border-left: 0.222rem solid var(--primary-color);
}
.pagination .page-numbers:last-child {
  border-right: 0.222rem solid var(--primary-color);
}
.pagination .page-numbers.current,
.pagination .page-numbers:focus,
.pagination .page-numbers:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.pagination .page-numbers.current svg,
.pagination .page-numbers:focus svg,
.pagination .page-numbers:hover svg {
  color: #fff;
}
.pagination .page-numbers.prev svg {
  transform: rotate(180deg);
}
.pagination .page-numbers.dots {
  pointer-events: none;
}
.pagination svg {
  color: var(--primary-color);
  width: 2.222rem;
  height: 1.389rem;
}
.language-switcher {
  position: relative;
  display: inline-block;
}
.language-switcher__list {
  padding: 0.389rem 1.056rem 0.278rem 0.389rem;
  position: relative;
  color: var(--primary-color);
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  font-size: 0.889rem;
  font-weight: 700;
  box-shadow: none;
}
@media (min-width: 1240px) {
  .language-switcher__list {
    margin-left: 1.667rem;
  }
}
.language-switcher:after {
  content: "";
  position: absolute;
  top: 0.75rem;
  right: 0.389rem;
  width: 0;
  height: 0;
  border-left: 0.278rem solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0.278rem solid transparent;
  border-top: 0.444rem solid var(--primary-color);
}
.g01 {
  position: relative;
  position: sticky;
  padding: 0 1.389rem;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0.111rem 0.889rem 0 rgba(21, 20, 22, 0.04);
  background-color: var(--primary-white);
  color: #fff;
  display: flex;
  align-items: center;
  transition: max-height 0.1s linear;
  height: 4rem;
}
@media (min-width: 1240px) {
  .g01 {
    height: 6.111rem;
    max-height: 6.111rem;
  }
}
/* .g01.is-scrolled {
  max-height: 4rem;
} */
/* .g01:after {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  content: "";
  background-color: var(--primary-color);
} */
body.is-search .g01:after {
  border-bottom: 1px solid #fff;
}
.g01__logo:focus,
.g01__logo:hover {
  outline: none;
}
.g01__logo-image {
  max-width: 12rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: left center;
  transition: transform 0.1s linear, opacity 1s ease !important;
}
@media (min-width: 1240px) {
  .g01__logo-image {
    transform: scale(1.53616);
  }
}
.is-scrolled .g01__logo-image {
  transform: none;
}
.g01__menu-wrapper {
  padding: 0 1.389rem;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  /* background-color: #ff0037 !important; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.25s ease;
  max-width: 22.222rem;
}
body.is-menu-active .g01__menu-wrapper {
  transform: none;
  transition: transform 0.5s ease;
}
@media (min-width: 1240px) {
  .g01__menu-wrapper {
    padding: 0;
    z-index: 0;
    background-color: transparent;
    position: relative;
    flex-grow: 1;
    width: auto;
    height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: unset;
    transform: none;
    max-width: none;
  }
}
@media (min-width: 1240px) {
  .g01__primary,
  .g01__primary-menu,
  .g01__secondary {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.g01__primary {
  margin-bottom: 1em;
}
@media (min-width: 1240px) {
  .g01__primary {
    margin: 0 0 0 4.6em;
  }
}
.g01__primary-menu,
.g01__secondary-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.g01__primary-menu .menu-item {
  height: 100%;
  position: relative;
  pointer-events: none;
}
@media (min-width: 1240px) {
  .g01__primary-menu .menu-item {
    display: inline-flex;
    align-items: center;
    margin-right: 1.5em;
  }
}
.g01__primary-menu .menu-item:after {
  position: absolute;
  bottom: 0;
  left: -0.111rem;
  width: calc(100% + 0.222rem);
  height: 0.611rem;
  background-color: currentColor;
  border-radius: 0.111rem 0.111rem 0 0;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 0.25s ease;
}
@media (min-width: 1240px) {
  .g01__primary-menu .menu-item:after {
    content: "";
  }
}
.g01__primary-menu .menu-item.current-menu-item:after,
.g01__primary-menu .menu-item:hover:after,
.g01__primary-menu .menu-item[focus-within]:after {
  transform: none;
}
.g01__primary-menu .menu-item.current-menu-item:after,
.g01__primary-menu .menu-item:focus-within:after,
.g01__primary-menu .menu-item:hover:after {
  transform: none;
}
.g01__primary-menu .menu-item a {
  font-size: 1.25rem;
  margin: 0.5em 0;
  display: block;
  color: inherit;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  pointer-events: all;
}
@media (min-width: 1240px) {
  .g01__primary-menu .menu-item a {
    font-size: 0.889rem;
    display: inline;
    margin: 0;
  }
  .menu-item-home {
    display: none !important;
  }
}
@media (min-width: 1240px) {
  .g01__secondary {
    flex-grow: 1;
    margin-left: auto;
    justify-content: flex-end;
  }
}
@media (min-width: 1240px) {
  .g01__secondary-menu {
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 1240px) {
  .g01__secondary-menu .menu-item {
    margin-left: 1.5em;
  }
}
.g01__secondary-menu .menu-item a {
  font-size: 1.25rem;
  margin: 0.5em 0;
  display: block;
  color: inherit;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
}
@media (min-width: 1240px) {
  .g01__secondary-menu .menu-item a {
    font-size: 0.889rem;
    display: inline;
    margin: 0;
  }
}
.g01__search-wrap {
  position: relative;
  pointer-events: none;
  margin-top: 1.8rem;
}
@media (min-width: 1240px) {
  .g01__search-wrap {
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin-top: unset;
  }
}
.g01__search-wrap:after {
  position: absolute;
  bottom: 0;
  left: -0.111rem;
  width: calc(100% + 0.222rem);
  height: 0.611rem;
  background-color: currentColor;
  border-radius: 0.111rem 0.111rem 0 0;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 0.25s ease;
}
@media (min-width: 1240px) {
  .g01__search-wrap:after {
    content: "";
  }
}
.g01__search-wrap.is-active:after,
.g01__search-wrap:hover:after,
.g01__search-wrap[focus-within]:after {
  transform: none;
}
.g01__search-wrap.is-active:after,
.g01__search-wrap:focus-within:after,
.g01__search-wrap:hover:after {
  transform: none;
}
.g01__search {
  width: 1rem;
  height: 1rem;
  color: inherit;
  border: 0;
  padding: 0;
  pointer-events: all;
  background-color: transparent;
}
.g01__search svg {
  width: 100%;
  height: 100%;
}
.g01__hamburger {
  width: 1.778rem;
  height: 1.778rem;
  border: 0;
  padding: 0;
  margin-left: auto;
  position: relative;
  background-color: transparent;
}
@media (min-width: 1240px) {
  .g01__hamburger {
    display: none;
  }
}
.g01__hamburger:after,
.g01__hamburger:before {
  content: "";
  width: 1.556rem;
  height: 0.167rem;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -0.111rem 0 0 -0.778rem;
}
.g01__hamburger:before {
  transform: translateY(-0.333rem);
}
body.is-menu-active .g01__hamburger:before {
  transform: rotate(45deg);
}
.g01__hamburger:after {
  transform: translateY(0.333rem);
}
body.is-menu-active .g01__hamburger:after {
  transform: rotate(-45deg);
}
.g01__scroll-detection {
  position: absolute;
  width: 1px;
  height: 2.778rem;
  top: 11.111rem;
  left: 0;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.g02 {
  background-color: var(--primary-color);
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
  z-index: 1;
  transition: transform 0.25s ease;
  padding: 3em 0 1.5em;
}
@media (min-width: 560px) {
  .g02 {
    padding: 3.1em 0 4.4em;
  }
}
@media (min-width: 1240px) {
  .g02 {
    display: flex;
    justify-content: center;
    top: 100%;
    height: auto;
    z-index: -2;
    transform: translateY(-100%);
  }
}
body.is-search .g02 {
  display: block;
}
@media (min-width: 1240px) {
  body.is-search .g02 {
    transform: none;
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
.g02__content {
  margin: 0 auto;
  max-width: 43.333rem;
  width: 90%;
}
.g02__label {
  font-weight: 700;
  font-size: 0.889rem;
  display: block;
}
.g02__input-wrap {
  position: relative;
}
.g02__search {
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 2.667rem;
  padding: 0 4rem 0 0.5em;
}
@media (max-width: 809px) {
  .g02__search {
    font-size: 1rem;
  }
}
.g02__submit {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 100%;
  background: var(--primary-color);
  color: #fff;
  border: 0.222rem solid #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.g02__submit svg {
  width: 2.222rem;
  height: 1.389rem;
}
.g02__close {
  background-color: transparent;
  position: absolute;
  font-weight: 700;
  font-size: 1rem;
  padding: 0;
  border: 0;
  right: 1.389rem;
  top: 1.556rem;
  color: inherit;
  display: inline-flex;
  align-items: center;
}
.g02__close svg {
  width: 0.722rem;
  height: 0.722rem;
  margin-right: 0.389rem;
}
.g03 {
  position: relative;
  border-top: 0.111rem solid;
  background-color: var(--primary-white);
  color: #fff;
  padding: 1em 0;
}

.g03__logo {
  align-self: center;
}

.g03__container {
  margin-bottom: 2em;
}
@media (min-width: 810px) {
  .g03__container {
    margin-bottom: 3em;
  }
}
@media (min-width: 1040px) {
  .g03__container {
    display: flex;
    justify-content: space-between;
  }
}
.g03__bottom {
  font-size: 0.778rem;
  font-weight: 700;
}
@media (min-width: 1040px) {
  .g03__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }
}
@media (min-width: 1040px) {
  .g03__logo {
    margin-right: 6.3em;
  }
}
@media (max-width: 1040px) {
  .g03__copyright {
    padding: 1rem 0rem;
    font-size: 0.7rem;
  }
}
@media (min-width: 1040px) {
  .g03__company-info {
    margin-right: auto;
  }
}
@media (min-width: 1040px) {
  .g03__social {
    display: flex;
    align-items: center;
  }
}
.g03__social-links {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.g03__social-label {
  margin-right: 1.8em;
}
.g03__social-item:not(:last-child) {
  margin-right: 1.7em;
}
.g03__social-item a {
  color: inherit;
  text-decoration: none;
}
.g03__social-item .svg-icon--icon-linkedin {
  width: 0.778rem;
  height: 0.889rem;
}
.g03__social-item .svg-icon--icon-twitter {
  width: 0.833rem;
  height: 0.833rem;
}
.g03__social-item .svg-icon--icon-youtube {
  width: 0.944rem;
  height: 0.833rem;
}
.g03__social-item .svg-icon--icon-instagram {
  width: 0.833rem;
  height: 0.833rem;
}
.g03__social-item .svg-icon--icon-facebook {
  width: 0.444rem;
  height: 0.722rem;
}
.g03__social-item .svg-icon--icon-wechat {
  width: 0.944rem;
  height: 0.944rem;
}
.g03__social-item .svg-icon--icon-tiktok {
  width: 0.611rem;
  height: 0.778rem;
}
.g03__back-to-top {
  border: 0;
  padding: 0;
  color: var(--primary-color);
  background-color: #fff;
  width: 2.667rem;
  height: 2.667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.444rem;
  line-height: 0.556rem;
  letter-spacing: 1.5px;
  font-weight: 900;
}
.g03__back-label:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto 0.4em;
  border-left: 0.222rem solid transparent;
  border-bottom: 0.389rem solid var(--primary-color);
  border-right: 0.222rem solid transparent;
  border-top: 0 solid transparent;
}
.g03__navigation {
  font-size: 0.778rem;
  font-weight: 700;
}
@media (min-width: 1040px) {
  .g03__navigation {
    display: flex;
    justify-content: space-between;
  }
}
.g03__navigation a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0.5em 0;
  line-height: 1.6;
}
.g03__navigation a[target="_blank"] {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.g03__navigation a[target="_blank"]:before {
  content: "";
  width: 0.833rem;
  height: 0.833rem;
  background-image: url(assets/svg/icon_external.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  display: block;
  margin-right: 0.3em;
}
.g03 .gform_wrapper a:not([class]) {
  color: inherit;
}
@media (min-width: 1040px) {
  .g03 .gform_wrapper {
    width: calc(50% - 0.95em);
  }
}
@media (min-width: 1040px) {
  .g03__column {
    width: calc(50% - 0.5em);
  }
}
.g03__footer-menu {
  padding: 0;
  list-style: none;
  margin: 0 0 1.5em;
}
@media (min-width: 810px) {
  .g03__footer-menu {
    margin: 0 0 3.8em;
  }
}
.g03__contact-heading {
  text-transform: uppercase;
  margin-bottom: 0.8em;
}
.g03 input[type="email"],
.g03 input[type="number"],
.g03 input[type="password"],
.g03 input[type="search"],
.g03 input[type="tel"],
.g03 input[type="text"],
.g03 input[type="url"],
.g03 select,
.g03 textarea {
  border-color: #fff;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.g03 input[type="email"]:focus,
.g03 input[type="number"]:focus,
.g03 input[type="password"]:focus,
.g03 input[type="search"]:focus,
.g03 input[type="tel"]:focus,
.g03 input[type="text"]:focus,
.g03 input[type="url"]:focus,
.g03 select:focus,
.g03 textarea:focus {
  border-color: #e0e5e5;
}
.gfield_error .g03 input[type="email"],
.gfield_error .g03 input[type="number"],
.gfield_error .g03 input[type="password"],
.gfield_error .g03 input[type="search"],
.gfield_error .g03 input[type="tel"],
.gfield_error .g03 input[type="text"],
.gfield_error .g03 input[type="url"],
.gfield_error .g03 select,
.gfield_error .g03 textarea {
  border-color: rgba(var(--primary-color), 0.5);
}
.g03 input[type="checkbox"]:checked + label:after,
.g03 input[type="checkbox"]:focus + label:before,
.g03 input[type="radio"]:checked + label:after,
.g03 input[type="radio"]:focus + label:before {
  border-color: #e0e5e5;
}
.g03 input[type="checkbox"] + label:after,
.g03 input[type="checkbox"] + label:before,
.g03 input[type="radio"] + label:after,
.g03 input[type="radio"] + label:before {
  border: 0.167rem solid #fff;
}
.g03 .gform_button,
.g03 input[type="submit"] {
  font-size: 0.889rem;
}
.g03 .gform_heading {
  margin-bottom: 0.75em;
}
.g03 .gform_footer {
  margin-top: 1em;
}
.g03 .gform_title {
  text-transform: uppercase;
  margin-bottom: 0.8em;
}
.g03 .gform_description {
  font-size: 0.778rem;
  line-height: 1.222rem;
}
.g03 .archive-container__filter-wrap,
.g03 .gfield,
.g03 .m18__filter-wrap {
  margin-bottom: 0.2em;
}
@media (min-width: 810px) {
  .g03 .archive-container__filter-wrap,
  .g03 .gfield,
  .g03 .m18__filter-wrap {
    width: calc(50% - 0.6em);
  }
}
.g03 .gfield_checkbox,
.g03 .gfield_consent,
.g03 .gfield_radio,
.g03 .gfield_textarea {
  width: 100%;
  margin-top: 0.6em;
}
.g03 .archive-container__filter-wrap label,
.g03 .gfield label,
.g03 .m18__filter-wrap label {
  font-size: 0.778rem;
}
.g03 .archive-container__filter-wrap input[type="checkbox"] + label,
.g03 .archive-container__filter-wrap input[type="radio"] + label,
.g03 .gfield input[type="checkbox"] + label,
.g03 .gfield input[type="radio"] + label,
.g03 .m18__filter-wrap input[type="checkbox"] + label,
.g03 .m18__filter-wrap input[type="radio"] + label {
  font-size: 0.667rem;
  padding: 0 0 0 1.667rem;
}
.g03__address {
  line-height: 1.722rem;
  font-style: normal;
  margin-bottom: 3.8em;
}
@media (max-width: 809px) {
  .g03__address {
    margin-bottom: 1.5em;
    line-height: 1.5;
  }
}
.g03__address p {
  margin: 0;
}
.m01--autoplay .can-play .m16__fallback-image {
  opacity: 0;
}
.m02 {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m02 {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
}
.m02 + .m05 {
  margin-top: -2rem;
}
@media (min-width: 810px) {
  .m02 + .m05 {
    margin-top: -5rem;
  }
}
.m02__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
  margin-bottom: 1.25em;
}
.m02__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
.m02__heading {
  position: relative;
  z-index: 2;
  margin-bottom: 0.5em;
}
@media (min-width: 810px) {
  .m02__heading {
    margin-bottom: 1.13em;
  }
}
.m02__heading-text {
  display: block;
}
.m02__button-wrap {
  margin-top: 1.95em;
}
@media (min-width: 810px) {
  .m02__content {
    width: 38rem;
    max-width: calc(50% + 5rem);
  }
}
.m02--no-media .m02__content {
  width: auto !important;
  max-width: none !important;
}
.m02__media {
  margin-top: 3rem;
}
@media (min-width: 810px) {
  .m02__media {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(50% - 10rem);
  }
}
@media (min-width: 1200px) {
  .m02__media {
    width: calc(50% - 20rem);
  }
}
.m02__media__image {
  width: 100%;
}
@media (min-width: 810px) {
  .m02__media__image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.m03 {
  margin-top: 3rem;
  margin-bottom: 6rem;
}
.m03__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
  margin-bottom: 1.4rem;
}
.m03__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
.m03__wrapper {
  overflow: hidden;
  margin-top: 2rem;
}
.m03__container {
  display: flex;
}
.is-static .m03__container {
  justify-content: center;
  transform: none !important;
}
.m03__slide {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 1.111rem;
}
@media (min-width: 810px) {
  .m03__slide {
    margin: 0 3.111rem;
  }
}
.m03__media {
  margin: auto;
}
.m03__media__image {
  margin: auto;
  /* max-width: 200px; */
  max-height: 85px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  padding: 4px;
}
.m04 {
  color: #fff;
  background-color: var(--primary-color);
  padding: 3rem 0;
}
@media (min-width: 810px) {
  .m04 {
    padding: 5.15rem 0 6.2rem;
  }
}

@media (max-height: 768px) and (min-width: 600px) {
  .m04 {
    padding: 2.15rem 0 0.2rem !important;
  }

  .m04__slide {
    max-height: 430px !important;
  }

  .has-heading-1-font-size,
  .heading--1,
  h1 {
    font-size: 4.1rem;
  }

  .m04__introduction {
    margin-bottom: 0rem !important;
  }
  .m06 {
    margin-top: 3.25rem !important;
  }

  .m06__introduction {
    margin-bottom: 1.85em !important;
  }

  .m06__carousel {
    max-height: 430px;
  }

  .m06__content {
    padding: 1.7em 0 1.8em;
    margin-top: 3rem !important;
  }

  /* .m05__container {
    max-height: 430px;
  } */

  .m05__media {
    min-width: 40% !important;
  }
  .m05__content {
    width: auto !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  .m02__content {
    width: auto !important;
  }

  .has-heading-4-font-size,
  .heading--4,
  .single-takeout-item__heading,
  h4 {
    font-size: 1.5rem !important;
  }

  body {
    overflow-x: hidden;
  }

  .m02__label {
    margin-bottom: 0.5em !important;
  }
}

@media (max-width: 599.999px) {
  .padding-description {
    padding: 0rem;
  }
  .form-container .container {
    width: 100%;
  }
}
.m10 .single-service-item__excerpt {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 425px) {
  .form-container .container {
    width: 100%;
  }

  .m10 .single-service-item__link {
    padding: 0 6vw 0rem !important;
  }
  .m10 .single-service-item__excerpt {
    margin: 1.667rem 0 0;
  }

  .m10__header {
    margin: 1.222rem auto 0.45rem !important;
  }
  .m10 .single-service-item__heading {
    margin-top: 0em !important;
  }

  .m10__container {
    overflow: auto;
  }

  .m10__button-wrap {
    display: none !important;
  }
  .m04__wrapper {
    overflow: auto !important;
  }
}

.m04__introduction {
  margin-bottom: 2rem;
}

@media (min-width: 810px) {
  .m04__introduction {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.m04__heading {
  margin-right: 0.65em;
}
.m04__button {
  margin-top: 1em;
}
@media (min-width: 810px) {
  .m04__button {
    margin: 0.5em 0;
  }
}
@media (max-width: 809px) {
  .m04__button:not(.m04__button--mobile) {
    display: none;
  }
}
.m04__list {
  display: none;
  width: 100%;
}
@media (min-width: 810px) {
  .m04__list {
    display: flex;
    margin-bottom: 2.75rem;
  }
}
.m04__list .magic-line-inner {
  display: flex;
}
.m04__navigation {
  position: relative;
}
.m04__navigation:before {
  display: block;
  position: relative;
  margin-left: -50vw;
  max-width: 100vw;
  width: 100vw;
  left: 50%;
  height: 2px;
  background-color: #a10023;
  margin-top: 4.7rem;
}
@media (min-width: 1040px) {
  .m04__navigation:before {
    content: "";
  }
}
.m04__select-wrapper {
  position: relative;
  display: block;
  margin: 0 auto 3rem;
}
.m04__select-wrapper:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 2.667rem;
  height: 100%;
  pointer-events: none;
  background-image: url(assets/svg/ico_select.svg);
  background-repeat: repeat-y;
  background-position: 50%;
  border: 0.167rem solid #fff;
}
@media (min-width: 810px) {
  .m04__select-wrapper {
    display: none;
  }
}
.m04__select {
  display: block;
  width: 100%;
  font-size: 1rem;
  border: 0.167rem solid #fff;
  border-radius: 0;
  background-color: #fff;
  padding: 0.65em 1em;
}
.m04__nav-button {
  padding: 1.4em 1.7em 0;
  /* margin: 0 1em 0 0; */
  border: 0;
  font-weight: 700;
  background-color: transparent;
  color: #a10023;
  border-bottom: 0.222rem solid transparent;
  margin-top: -2px;
}
@media (min-width: 1040px) {
  .m04__nav-button {
    border: 0;
  }
}
.m04__nav-button:focus {
  outline: none;
}
.m04__nav-button.active,
.m04__nav-button:focus,
.m04__nav-button:hover {
  color: #fff;
  border-color: currentColor;
  border-top: 2px solid;
}
.m04__wrapper {
  overflow: hidden;
}
.m04__container {
  display: flex;
  margin-left: -5vw;
}
@media (min-width: 1040px) {
  .m04__container {
    margin-left: unset;
  }
}
.m04__slide {
  position: relative;
  flex: 0 0 92.5%;
  max-width: 66rem;
  padding-right: 6vw;
}

@media (min-width: 1040px) {
  .m04__slide {
    display: flex;
    align-items: center;
    padding: 0;
    max-height: 500px;
  }
}
.m04__title {
  margin-bottom: 0.53em;
}
.m04__media {
  position: relative;
}
.m04__media:before {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
@media (min-width: 1040px) {
  .m04__media {
    max-width: 37.5rem;
    width: calc(100% - 28rem);
    margin-right: 2.5rem;
  }
}
.m04__media__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m04__content {
  margin-top: 2em;
}

@media (min-width: 768px) and (max-height: 768px) {
  .m04__media__image {
    height: 80% !important;
    width: 80% !important;
  }
}
@media (min-width: 1040px) {
  .m04__content {
    margin: 0;
    width: 26rem;
    max-width: 50%;
    padding-right: 3rem;
  }
}
.init-magic-line,
.magic-line-inner {
  position: relative;
}
.magic-line {
  z-index: 1;
  position: absolute;
  top: -2px !important;
  background-color: currentColor;
}
@media (max-width: 1039px) {
  .magic-line {
    display: none !important;
  }
}
.magic-line-css-transition {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
body.gaming-theme .m04__select-wrapper:after {
  background-image: url(assets/svg/ico_select_purple.svg);
}
.m05 {
  margin-top: 3rem;
  margin-bottom: 7.444rem;
}
@media (min-width: 810px) {
  .m05 {
    margin-top: 7.9rem;
    margin-bottom: 4rem;
    padding-bottom: 4.444rem;
    overflow: hidden;
  }
}
.m05__container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 810px) {
  .m05__container {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
.m05__media {
  width: 100%;
  position: relative;
  z-index: 1;
  order: 2;
  margin-top: 3em;
}
@media (min-width: 810px) {
  .m05__media {
    order: -1;
    width: calc(100% - 26.111rem);
    max-width: 35.167rem;
    min-width: 50%;
    margin: 0 0 0 -2.222rem;
  }
}
.m05__media:before {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  left: -4.444rem;
  bottom: -4.444rem;
  z-index: -1;
  background-color: var(--primary-color);
  animation: float 5s ease infinite forwards;
}
@media (min-width: 810px) {
  .m05--left .m05__media {
    order: 2;
    margin: 0 -2.222rem 0 0;
  }
}
@media (min-width: 810px) {
  .m05--left .m05__media:before {
    left: auto;
    right: -4.444rem;
  }
}
.m05__media__image {
  width: 100%;
}
@media (min-width: 810px) {
  .m05__content {
    width: 26.111rem;
    max-width: 50%;
  }
}
.m05__button-wrap {
  margin-top: 1.95em;
}
.m05 + .m05 .m05__media:before {
  animation-delay: 1.5s;
}
.m06 {
  margin-top: 3rem;
}
@media (min-width: 810px) {
  .m06 {
    margin-top: 5.25rem;
  }
}
.m06__introduction {
  margin-bottom: 2.4em;
}
@media (min-width: 810px) {
  .m06__introduction {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 3.85em;
  }
}
.m06__heading {
  margin-right: 0.65em;
}
.m06__button {
  margin-top: 2em;
}
@media (min-width: 810px) {
  .m06__button {
    margin: 0.5em 0;
  }
}
@media (max-width: 809px) {
  .m06__button:not(.m06__button--mobile) {
    display: none;
  }
}
.m06__carousel {
  display: flex;
  flex-direction: column;
}
@media (min-width: 810px) {
  .m06__carousel {
    flex-direction: row;
  }
}
.m06__content {
  position: relative;
  background-color: var(--primary-color);
  color: #fff;
  padding: 0 0 3em;
}
@media (min-width: 810px) {
  .m06__content {
    padding: 2.7em 0 5.8em;
    width: 50%;
    max-width: 34rem;
    margin-top: 10rem;
  }
}
.m06__mobile-media {
  margin-bottom: 2.3em;
  position: relative;
}
@media (min-width: 810px) {
  .m06__mobile-media {
    display: none;
  }
}
.m06__mobile-media:before {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
.m06__mobile-media .m06__slide-image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m06__slide-media {
  position: relative;
}
@media (max-width: 809px) {
  .m06__slide-media {
    height: 100%;
  }
}
@media (max-width: 809px) {
  .m06__slide-media img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.m06__media-link {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.m06__media {
  display: none;
}
@media (min-width: 810px) {
  .m06__media {
    width: calc(100% - 34rem);
    order: -2;
    display: block;
    position: relative;
  }
}
.m06__slide-heading__link {
  text-decoration: none;
  color: #fff;
}

.m06__slide-heading__link:hover {
  color: #fff;
}
.m06__slide-link {
  color: inherit;
  font-weight: 700;
  text-decoration: underline;
}
.m06__slide-excerpt {
  margin-bottom: 0;
}
.m06__wrapper {
  overflow: hidden;
  width: 100%;
}
.m06__container {
  display: flex;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.m06__slide {
  position: relative;
  flex: 0 0 100%;
}
.m06__controls,
.m06__slide-content {
  margin: 0 auto;
  width: 90%;
}
@media (min-width: 810px) {
  .m06__controls,
  .m06__slide-content {
    width: 80%;
    max-width: 26.222rem;
  }
}
.m06__controls {
  display: flex;
  margin-top: 2em;
}
.m06__nav svg {
  display: none;
  width: 0;
  margin: 0;
  opacity: 0;
  transition: width 0.3s ease, opacity 0.3s ease;
}
@media (min-width: 810px) {
  .m06__nav svg {
    display: block;
  }
}
.m06__nav:focus svg,
.m06__nav:hover svg {
  width: 2.333rem;
  opacity: 1;
}
.m06__nav--prev svg {
  transform: rotate(180deg);
}
.m06__nav--prev:focus svg,
.m06__nav--prev:hover svg {
  margin-right: 0.444rem;
}
.m06__nav--next:focus svg,
.m06__nav--next:hover svg {
  margin-left: 0.444rem;
}
.m06__nav[disabled] {
  opacity: 0.15;
  filter: grayscale(1);
  pointer-events: none;
  z-index: -1;
  background-color: var(--primary-color);
  color: #fff;
}
.m06__nav:not(:last-child) {
  margin-right: 0.889rem;
}
.m06__dots {
  max-width: 100%;
  display: flex;
  position: absolute;
  bottom: 1.333rem;
  right: 1.333rem;
}
.m06__dot {
  position: relative;
  display: block;
  padding: 0;
  width: 17px;
  height: 17px;
  margin-right: 8px;
  border: 3px solid var(--primary-color);
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.m06__dot:last-child {
  margin-right: 0;
}
.m06__dot:before {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  content: "";
  opacity: 0;
  transform: scale(0);
  background-color: var(--primary-color);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.m06__dot:focus:before,
.m06__dot:hover:before {
  opacity: 1;
  transform: scale(0.5);
}
.m06__dot.is-selected:before {
  opacity: 1;
  transform: scale(1);
}
.m06__taxonomies {
  list-style: none;
  padding: 0;
  margin: 0.7em 0 2.25em;
  display: flex;
  font-size: 0.889rem;
}
.m06__taxonomy:not(:last-child) {
  margin-right: 0.5em;
  padding-right: 0.5em;
  position: relative;
}
.m06__taxonomy:not(:last-child):after {
  content: "|";
  position: absolute;
  right: -0.2em;
  top: 0;
}
.m06__wrapper--content .m06__slide {
  opacity: 0;
  transition: opacity 0s ease 0.6s;
}
.m06__wrapper--content .m06__slide.is-selected {
  transition: opacity 0.6s ease;
  opacity: 1;
}
@media (min-width: 810px) {
  .m06__wrapper--media,
  .m06__wrapper--media .m06__container,
  .m06__wrapper--media .m06__slide {
    height: 100%;
    min-height: 100%;
  }
}
@media (min-width: 810px) {
  .m06__wrapper--media .m06__slide-image,
  .m06__wrapper--media .m06__slide-media {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.archive-container,
.m07 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .archive-container,
  .m07 {
    margin-top: 4.65rem;
    margin-bottom: 5.3rem;
  }
}
.m07__introduction {
  margin-bottom: 2.4em;
}
@media (min-width: 810px) {
  .m07__introduction {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 3.21em;
  }
}
.m07__heading {
  margin-right: 0.65em;
}
.m07__button {
  margin-top: 1em;
}
@media (min-width: 810px) {
  .m07__button {
    margin: 0.5em 0;
  }
}
@media (min-width: 810px) {
  .archive-container__items-grid,
  .m07__insights {
    margin: 0 -20px;
  }
}
.archive-container .single-case-study-item,
.archive-container .single-insight-item,
.m07 .single-case-study-item,
.m07 .single-insight-item {
  width: 100%;
  padding-bottom: 0.65em;
}
@media (min-width: 810px) {
  .archive-container .single-case-study-item,
  .archive-container .single-insight-item,
  .m07 .single-case-study-item,
  .m07 .single-insight-item {
    width: calc(50% - 40px);
  }
}
.m08 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m08 {
    margin-top: 7.3rem;
    margin-bottom: 7.35rem;
  }
}
.m08__wrapper {
  overflow: hidden;
}
.m08__container {
  display: flex;
}
.m08__slide {
  position: relative;
  min-width: 100%;
  opacity: 0;
  transition: opacity 1s ease;
}
.m08__slide.is-selected {
  opacity: 1;
}
.m08__quote {
  max-width: 43.167rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.m08__content {
  margin: 0.75em 0 0.4em;
}
@media (min-width: 810px) {
  .m08__content {
    font-size: 1.778rem;
    line-height: 2.5rem;
  }
}
.m08__content p:first-of-type {
  margin-top: 0;
}
.m08__content p:first-of-type:before {
  content: "“";
}
.m08__content p:last-of-type {
  margin-bottom: 0;
}
.m08__content p:last-of-type:after {
  content: "”";
}
.m08__image {
  width: 2.778rem;
  height: 2.778rem;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0 auto 0.4em;
  order: -2;
}
.m08__cite {
  order: -1;
  font-size: 0.889rem;
  line-height: 1.222rem;
  font-style: normal;
}
.m08__company,
.m08__name {
  display: block;
}
.m08__name {
  font-weight: 700;
}
.m08__button-wrap {
  display: flex;
  justify-content: center;
}
.m08__button {
  padding-left: 0.9em;
  padding-right: 0.9em;
}
.m08__button svg {
  width: 2.222rem;
  height: 1.333rem;
  margin: 0;
}
.m08__button--prev {
  margin-right: -0.222rem;
}
.m08__button--prev svg {
  transform: rotate(180deg);
}
.m08__button[disabled] {
  opacity: 0;
  pointer-events: none;
}
.m09 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3rem 0;
}
@media (min-width: 810px) {
  .m09 {
    padding: 4.85rem 0 4rem;
  }
}
.m09__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
  margin-bottom: 1.25em;
}
.m09__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
.m09__heading {
  position: relative;
  z-index: 2;
  margin-bottom: 0.69em;
}
.m09__heading-text {
  display: block;
}
.m09 .gform_wrapper {
  background-color: #fff;
  color: #151416;
  padding: 8vw 7vw 9vw;
}
@media (min-width: 810px) {
  .m09 .gform_wrapper {
    padding: 4.167rem 9% 4.444rem;
  }
}
.m09--red-full .gform_wrapper {
  color: #fff;
  background-color: transparent;
  padding: 0;
}
@media (min-width: 810px) {
  .m09--red-full .gform_wrapper {
    padding: 4.167rem 9% 4.444rem;
  }
}
.m09--red-full .gform_wrapper input[type="email"],
.m09--red-full .gform_wrapper input[type="number"],
.m09--red-full .gform_wrapper input[type="password"],
.m09--red-full .gform_wrapper input[type="search"],
.m09--red-full .gform_wrapper input[type="tel"],
.m09--red-full .gform_wrapper input[type="text"],
.m09--red-full .gform_wrapper input[type="url"],
.m09--red-full .gform_wrapper select,
.m09--red-full .gform_wrapper textarea {
  width: 100%;
  font-size: 1rem;
  border: 0.167rem solid #fff;
  border-radius: 0;
  background-color: #fff;
  padding: 0.65em 1em;
}
.gfield_error .m09--red-full .gform_wrapper input[type="email"],
.gfield_error .m09--red-full .gform_wrapper input[type="number"],
.gfield_error .m09--red-full .gform_wrapper input[type="password"],
.gfield_error .m09--red-full .gform_wrapper input[type="search"],
.gfield_error .m09--red-full .gform_wrapper input[type="tel"],
.gfield_error .m09--red-full .gform_wrapper input[type="text"],
.gfield_error .m09--red-full .gform_wrapper input[type="url"],
.gfield_error .m09--red-full .gform_wrapper select,
.gfield_error .m09--red-full .gform_wrapper textarea {
  border-color: #e0e5e5;
}
.m09--red-full .gform_wrapper input[type="email"][disabled],
.m09--red-full .gform_wrapper input[type="number"][disabled],
.m09--red-full .gform_wrapper input[type="password"][disabled],
.m09--red-full .gform_wrapper input[type="search"][disabled],
.m09--red-full .gform_wrapper input[type="tel"][disabled],
.m09--red-full .gform_wrapper input[type="text"][disabled],
.m09--red-full .gform_wrapper input[type="url"][disabled],
.m09--red-full .gform_wrapper select[disabled],
.m09--red-full .gform_wrapper textarea[disabled] {
  pointer-events: none;
}
.m09--red-full .gform_wrapper input[type="email"]:focus,
.m09--red-full .gform_wrapper input[type="number"]:focus,
.m09--red-full .gform_wrapper input[type="password"]:focus,
.m09--red-full .gform_wrapper input[type="search"]:focus,
.m09--red-full .gform_wrapper input[type="tel"]:focus,
.m09--red-full .gform_wrapper input[type="text"]:focus,
.m09--red-full .gform_wrapper input[type="url"]:focus,
.m09--red-full .gform_wrapper select:focus,
.m09--red-full .gform_wrapper textarea:focus {
  border-color: #e0e5e5;
}
.m09--red-full .gform_wrapper .ginput_container_select:after {
  transform: scale(0.9);
}
.m09--red-full .button {
  margin-top: 1em;
}
.m09--red-full a:not([class]) {
  color: inherit;
}
.m09--white {
  padding: 0;
  margin: 3rem 0;
  background-color: #fff;
  color: #151416;
}
@media (min-width: 810px) {
  .m09--white {
    margin: 5rem 0 4rem;
  }
}
.m09--white .gform_wrapper {
  color: #fff;
  background-color: var(--primary-color);
}
.m09--white .gform_wrapper input[type="email"],
.m09--white .gform_wrapper input[type="number"],
.m09--white .gform_wrapper input[type="password"],
.m09--white .gform_wrapper input[type="search"],
.m09--white .gform_wrapper input[type="tel"],
.m09--white .gform_wrapper input[type="text"],
.m09--white .gform_wrapper input[type="url"],
.m09--white .gform_wrapper select,
.m09--white .gform_wrapper textarea {
  width: 100%;
  font-size: 1rem;
  border: 0.167rem solid #fff;
  border-radius: 0;
  background-color: #fff;
  padding: 0.65em 1em;
}
.gfield_error .m09--white .gform_wrapper input[type="email"],
.gfield_error .m09--white .gform_wrapper input[type="number"],
.gfield_error .m09--white .gform_wrapper input[type="password"],
.gfield_error .m09--white .gform_wrapper input[type="search"],
.gfield_error .m09--white .gform_wrapper input[type="tel"],
.gfield_error .m09--white .gform_wrapper input[type="text"],
.gfield_error .m09--white .gform_wrapper input[type="url"],
.gfield_error .m09--white .gform_wrapper select,
.gfield_error .m09--white .gform_wrapper textarea {
  border-color: #e0e5e5;
}
.m09--white .gform_wrapper input[type="email"][disabled],
.m09--white .gform_wrapper input[type="number"][disabled],
.m09--white .gform_wrapper input[type="password"][disabled],
.m09--white .gform_wrapper input[type="search"][disabled],
.m09--white .gform_wrapper input[type="tel"][disabled],
.m09--white .gform_wrapper input[type="text"][disabled],
.m09--white .gform_wrapper input[type="url"][disabled],
.m09--white .gform_wrapper select[disabled],
.m09--white .gform_wrapper textarea[disabled] {
  pointer-events: none;
  background-color: #e0e5e5;
}
.m09--white .gform_wrapper input[type="email"]:focus,
.m09--white .gform_wrapper input[type="number"]:focus,
.m09--white .gform_wrapper input[type="password"]:focus,
.m09--white .gform_wrapper input[type="search"]:focus,
.m09--white .gform_wrapper input[type="tel"]:focus,
.m09--white .gform_wrapper input[type="text"]:focus,
.m09--white .gform_wrapper input[type="url"]:focus,
.m09--white .gform_wrapper select:focus,
.m09--white .gform_wrapper textarea:focus {
  border-color: #e0e5e5;
}
.m09--white .gform_wrapper .ginput_container_select:after {
  transform: scale(0.9);
}
.m09--white a:not([class]) {
  color: inherit;
}
.m10 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m10 {
    margin-top: 5.1rem;
    margin-bottom: 7.3rem;
  }
}
@media (min-width: 1040px) {
  .m10.add-offset .m10__container {
    position: relative;
    left: 7%;
  }
}
.m10__wrapper {
  overflow: hidden;
}
.m10__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 2.222rem auto 1.111rem;
}
@media (min-width: 810px) {
  .m10__header {
    margin: 2.222rem auto 1.45rem;
  }
}
.m10__heading {
  margin-right: 2.778rem;
}
.m10__button-wrap {
  display: flex;
  justify-content: center;
}
@media (min-width: 810px) {
  .m10__button-wrap {
    margin: 1.111rem 0;
  }
}
.m10__button {
  padding-left: 1em;
  padding-right: 1em;
}
.m10__button--prev {
  margin-right: -0.222rem;
}
.m10__button--prev svg {
  transform: rotate(180deg);
}
.m10__button[disabled] {
  opacity: 0.15;
  filter: grayscale(1);
  pointer-events: none;
  z-index: -1;
  background-color: var(--primary-color);
  color: #fff;
}
.m10__filters {
  color: var(--primary-color);
  position: relative;
}
@media (min-width: 810px) {
  .m10__filters {
    margin-bottom: 3.333rem;
  }
}
.m10__filters:after,
.m10__filters:before {
  content: "";
  pointer-events: none;
  width: 5vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
@media (min-width: 810px) {
  .m10__filters:after,
  .m10__filters:before {
    display: none;
  }
}
.m10__filters:before {
  left: -5vw;
  background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
}
@media (min-width: 1040px) {
  .m10__filters:before {
    display: block;
    position: relative;
    margin-left: -50vw;
    max-width: 100vw;
    width: 100vw;
    left: 50%;
    height: 2px;
    background: #c9c9c9;
    margin-top: 4.2rem;
  }
}
.m10__filters:after {
  right: -5vw;
  background: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0));
}
.m10__filters .magic-line-inner {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 809px) {
  .m10__filters .magic-line-inner {
    margin: 0 -5vw 1.111rem;
    padding: 0 5vw;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.m10__filters .magic-line-inner::-webkit-scrollbar {
  display: none;
}
.m10__select-wrapper {
  position: relative;
  display: block;
  margin: 0 auto 3rem;
  width: 100%;
}
@media (min-width: 810px) {
  .m10__select-wrapper {
    display: none;
  }
}
.m10__select {
  width: 100%;
  font-size: 1rem;
  border-radius: 0;
  background-color: #fff;
  border: 0;
  border-bottom: 0.167rem solid var(--primary-color);
  border-right-color: var(--primary-color);
  border-top-color: var(--primary-color);
  -webkit-appearance: none;
  appearance: none;
  padding: 0.65em calc(2.5rem + 1em) 0.65em 0.75em;
  background-image: url(assets/svg/ico_select_simple.svg);
  background-repeat: no-repeat;
  background-position: right 0.75em center;
}
.m10__filter {
  display: none;
}
.m10__filter__label {
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  padding: 1.4em 1.5em 0;
  margin: 0 1em 0 0;
  border: 0;
  font-weight: 700;
  background-color: transparent;
  color: #c9c9c9;
  border-bottom: 0.222rem solid transparent;
  display: none;
}
@media (min-width: 810px) {
  .m10__filter__label {
    display: block;
  }
}
@media (min-width: 1040px) {
  .m10__filter__label {
    border: 0;
  }
}
.m10__filter__label:focus {
  outline: none;
}
.m10__filter__label.active,
.m10__filter__label:focus,
.m10__filter__label:hover {
  color: var(--primary-color);
  border-color: currentColor;
}
.m10__container {
  display: flex;
}
.m10__items {
  margin-top: 0;
}
@media (min-width: 810px) {
  .m10__items {
    margin-top: 2.667rem;
  }
}
.m10__slide {
  position: relative;
  opacity: 0;
  transition: opacity 1s ease;
  width: calc(100% - 10vw);
  min-width: calc(100% - 10vw);
  margin: 2.222rem 2.5vw;
  padding: 0;
}
@media (min-width: 810px) {
  .m10__slide {
    margin: 0 1.333rem 0 0;
    padding: 2.222rem 0;
    min-width: 25rem;
    width: 27rem;
  }
}
.m10__slide.is-selected {
  opacity: 1;
}
.m10__slide:first-child {
  padding-left: 5vw;
}
@media (min-width: 810px) {
  .m10__slide:first-child {
    width: calc(25rem + 5vw);
    min-width: calc(25rem + 5vw);
  }
}
.m10__slide:last-child {
  padding-right: 5vw;
}
@media (min-width: 810px) {
  .m10__slide:last-child {
    width: calc(27rem + 6vw);
    min-width: calc(27rem + 6vw);
  }
}
.m10 .single-service-item {
  position: relative;
}
.m10 .single-service-item__link {
  display: block;
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
  padding: 0 6vw 2.222rem;
  min-height: 100%;
}
@media (min-width: 810px) {
  .m10 .single-service-item__link {
    padding: 0 2.222rem 1.333rem;
  }
}
.m10 .single-service-item__heading {
  margin-top: 1em;
}
.m10 .single-service-item__media {
  width: 11.111rem;
  height: 11.111rem;
  border: 2px solid #fff;
  top: -2.222rem;
  position: relative;
}
.m10 .single-service-item__media + .single-service-item__content {
  margin-top: -2em;
}
.m10 .single-service-item__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m10 .single-service-item__image[src$=".svg"] {
  -o-object-fit: contain;
  object-fit: contain;
  padding: 30%;
  background-color: var(--primary-color);
}
.m10 .single-service-item__content {
  padding-bottom: 1.667rem;
}
.m10 .single-service-item__tag {
  background-color: transparent;
  padding: 0;
}
.m10 .single-service-item__excerpt {
  margin: 1.667rem 0 0;
}
.m10 .single-service-item__excerpt .read-more {
  text-decoration: underline;
}
.m11 {
  position: relative;
  margin-bottom: 3.5rem;
}
@media (min-width: 810px) {
  .m11 {
    margin-bottom: 8.3rem;
  }
}
@media (min-width: 940px) {
  .m11__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.m11__media {
  position: relative;
}
@media (min-width: 940px) {
  .m11__media {
    right: -5vw;
    width: 38rem;
    max-width: 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
@media (min-width: 1173px) {
  .m11__media {
    right: auto;
    left: calc(50vw - 33rem);
  }
}
.m11__media--one__image,
.m11__media--two__image {
  width: 100%;
}
@media (min-width: 940px) {
  .m11__media--one__image,
  .m11__media--two__image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    max-width: none;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.m11__media__image {
  width: 100%;
}
@media (min-width: 940px) {
  .m11__media__image {
    max-width: none;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.m11__content {
  margin: 4.8em 0 3.8em;
  position: relative;
}
@media (min-width: 940px) {
  .m11__content {
    width: 38.889rem;
    max-width: calc(100% - 27.778rem);
  }
}
.m11__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
  margin-bottom: 1.25em;
}
.m11__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
.m11__heading {
  position: relative;
  z-index: 2;
  margin-bottom: 0.6em;
}
.m11__heading-text {
  display: block;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  word-wrap: normal;
}
.m11__button-wrap {
  margin-top: 2em;
}
.m12 {
  overflow: hidden;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m12 {
    margin-top: 7.3rem;
    margin-bottom: 7.5rem;
  }
}
.m12__gallery {
  display: grid;
  grid-template-columns: 40% 20% 40%;
  margin: -0.333rem;
}
@media (min-width: 810px) {
  .m12__gallery {
    margin: -0.667rem;
  }
}
@media (min-width: 1100px) {
  .m12__gallery {
    grid-template-columns: 23.6% 33.9% 42.5%;
  }
}
.m12__media {
  position: relative;
  margin: 0.333rem;
}
@media (min-width: 810px) {
  .m12__media {
    margin: 0.667rem;
  }
}
.m12__media:before {
  content: "";
}
.m12__media:nth-child(4n + 1):before {
  padding-bottom: 95.4861111111%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
@media (min-width: 1100px) {
  .m12__media:nth-child(4n + 1):before {
    padding-bottom: 122.0588235294%;
    position: relative;
    width: 100%;
    height: 0;
    display: block;
  }
}
.m12__media:nth-child(4n + 2):before {
  padding-bottom: 63.6574074074%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
@media (min-width: 1100px) {
  .m12__media:nth-child(4n + 2):before {
    padding-bottom: 37.7272727273%;
    position: relative;
    width: 100%;
    height: 0;
    display: block;
  }
}
.m12__media:nth-child(4n + 3):before {
  padding-bottom: 63.6574074074%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
@media (min-width: 1100px) {
  .m12__media:nth-child(4n + 3):before {
    padding-bottom: 50.1207729469%;
    position: relative;
    width: 100%;
    height: 0;
    display: block;
  }
}
.m12__media:nth-child(4n + 4):before {
  padding-bottom: 95.4861111111%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
@media (min-width: 1100px) {
  .m12__media:nth-child(4n + 4):before {
    padding-bottom: 67.8104575163%;
    position: relative;
    width: 100%;
    height: 0;
    display: block;
  }
}
.m12__media:nth-child(4n + 2),
.m12__media:nth-child(4n + 3) {
  grid-column: span 2;
}
.m12__media__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m12--four-column .m12__gallery,
.m12--three-column .m12__gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0 calc(5vw - 0.167rem);
  margin-left: 0;
  margin-right: 0;
  max-width: none;
}
@media (min-width: 1310px) {
  .m12--four-column .m12__gallery,
  .m12--three-column .m12__gallery {
    padding: 0;
    max-width: 67.334rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.m12--four-column .m12__media:before,
.m12--three-column .m12__media:before {
  display: none !important;
}
.m12--four-column .m12__media__image,
.m12--three-column .m12__media__image {
  position: relative;
  width: 100%;
  height: auto;
  top: unset;
  left: unset;
}
.m12--three-column .m12__media {
  width: 100%;
}
@media (min-width: 700px) {
  .m12--three-column .m12__media {
    width: calc(33.333% - 1.333rem);
  }
}
.m12--four-column .m12__media {
  width: 100%;
}
@media (min-width: 700px) {
  .m12--four-column .m12__media {
    width: calc(25% - 1.333rem);
  }
}
.m13 {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
@media (min-width: 810px) {
  .m13 {
    margin-top: 7.6rem;
    margin-bottom: 8rem;
  }
}
.m13__wrapper {
  overflow: hidden;
}
.m13__container {
  display: flex;
}
.m13__introduction {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
@media (min-width: 810px) {
  .m13__introduction {
    margin-bottom: 3.9em;
  }
}
.m13__heading {
  margin-right: 0.55em;
}
.m13__slide {
  position: relative;
  margin-right: 1.333rem;
  opacity: 0;
  transition: opacity 1s ease;
  min-width: 100%;
  width: 100%;
  padding: 2.222rem 5vw;
}
@media (min-width: 810px) {
  .m13__slide {
    padding: 2.222rem 0;
    width: 25rem;
    min-width: 25rem;
  }
}
@media (min-width: 810px) {
  .m13__slide:first-child {
    padding-left: 5vw;
  }
}
@media (min-width: 810px) {
  .m13__slide:first-child,
  .m13__slide:last-child {
    width: calc(25rem + 5vw);
    min-width: calc(25rem + 5vw);
  }
  .m13__slide:last-child {
    padding-right: 5vw;
  }
}
.m13__slide.is-selected {
  opacity: 1;
}
.m13__slide-wrapper {
  padding: 0 2.222rem 3rem;
  background-color: var(--primary-color);
  color: #fff;
  min-height: 100%;
  position: relative;
}
.m13__hidden-link {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.m13__media {
  width: 10.667rem;
  position: relative;
  background-color: #fff;
  border: 0.167rem solid #fff;
  top: -2.222rem;
}
.m13__media:before {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
.m13__media__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m13__media__image[src$=".svg"] {
  -o-object-fit: contain;
  object-fit: contain;
  padding: 30%;
  background-color: var(--primary-color);
}
.m13__title {
  margin-bottom: 0.71em;
}
.m13__button-wrap {
  display: flex;
  justify-content: center;
}
.m13__button {
  padding-left: 0.9em;
  padding-right: 0.9em;
}
.m13__button svg {
  width: 2.222rem;
  height: 1.333rem;
  margin: 0;
}
.m13__button--prev {
  margin-right: -0.222rem;
}
.m13__button--prev svg {
  transform: rotate(180deg);
}
.m13__button[disabled] {
  opacity: 0.15;
  filter: grayscale(1);
  pointer-events: none;
  z-index: -1;
  background-color: var(--primary-color);
  color: #fff;
}
.m13__service-type {
  font-size: 0.889rem;
  margin-top: -1.65em;
}
.m13__button-wrapper {
  margin-top: 3.5em;
  display: flex;
  justify-content: center;
}
.m13__link-wrap {
  margin-top: -1em;
}
.m13__link {
  border-bottom: 1px solid;
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}
.m13 p:empty {
  display: none;
}
.m14 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.m14 + .m27 {
  margin-top: -0.9rem;
}
.m14__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
}
.m14__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
.m15 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3rem 0;
}
@media (min-width: 810px) {
  .m15 {
    padding: 5rem 0;
  }
}
@media (min-width: 1100px) {
  .m15__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.m15__awards {
  display: flex;
  flex-wrap: wrap;
  margin: -0.333rem;
}
@media (min-width: 810px) {
  .m15__awards {
    margin: -0.889rem;
  }
}
@media (min-width: 1100px) {
  .m15__awards {
    order: -1;
    width: 55%;
    max-width: 30.556rem;
  }
}
.m15__content {
  margin-bottom: 2em;
}
@media (min-width: 1100px) {
  .m15__content {
    margin: 0;
    width: 40%;
    max-width: 26.111rem;
  }
}
.m15__media {
  position: relative;
  background-color: #fff;
  display: block;
  width: calc(50% - 0.667rem);
  margin: 0.333rem;
}
@media (min-width: 810px) {
  .m15__media {
    width: calc(50% - 1.778rem);
    margin: 0.889rem;
  }
}
.m15__media:before {
  padding-bottom: 60.3846153846%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
.m15__media__image {
  position: absolute;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
}
@media (min-width: 810px) {
  .m15__media__image {
    top: 1.778rem;
    left: 2.667rem;
    width: calc(100% - 5.333rem);
    height: calc(100% - 3.556rem);
  }
}
.m16 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m16 {
    margin-top: 7.9rem;
    margin-bottom: 7.3rem;
  }
}
.m16__wrapper {
  position: relative;
  max-height: calc(100vh - 6.1rem);
  background-color: #151416;
}
.m16__wrapper:before {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
.m16__wrapper .plyr,
.m16__wrapper .plyr__video-wrapper,
.m16__wrapper iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
.m16__video {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: fill;
}
.can-play .m16__video + .m16__caption {
  opacity: 1;
  pointer-events: all;
}
.m16__caption {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
}
.plyr + .m16__caption {
  opacity: 1;
  pointer-events: all;
}
.m16__fallback-image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.is-paused .m16__fallback-image,
.is-playing .m16__fallback-image {
  opacity: 0;
}
.m17 {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
@media (min-width: 810px) {
  .m17 {
    margin-top: 4rem;
    margin-bottom: 8rem;
  }
}
.m17__wrapper {
  overflow: hidden;
}
.m17__container {
  display: flex;
}
.m17__introduction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2em;
}
@media (min-width: 810px) {
  .m17__introduction {
    margin-bottom: 3.9em;
  }
}
.m17__heading {
  margin-right: 0.55em;
}
.m17__slide {
  position: relative;
  margin-right: 1.333rem;
  opacity: 0;
  transition: opacity 1s ease;
  min-width: 100%;
  width: 100%;
  padding: 2.222rem 5vw;
}
@media (min-width: 810px) {
  .m17__slide {
    padding: 2.222rem 0;
    min-width: 25rem;
    width: 25rem;
  }
}
.m17__slide.is-selected {
  opacity: 1;
}
@media (min-width: 810px) {
  .m17__slide:first-child {
    padding-left: 5vw;
  }
}
@media (min-width: 810px) {
  .m17__slide:first-child,
  .m17__slide:last-child {
    width: calc(25rem + 5vw);
    min-width: calc(25rem + 5vw);
  }
  .m17__slide:last-child {
    padding-right: 5vw;
  }
}
.m17__slide .read-more {
  color: inherit;
  font-weight: 700;
  text-decoration: underline;
}
.m17__slide-wrapper {
  padding: 0 6vw 2.222rem;
  background-color: var(--primary-color);
  color: #fff;
  min-height: 100%;
}
@media (min-width: 810px) {
  .m17__slide-wrapper {
    padding: 0 2.222rem 3rem;
  }
}
.m17__media {
  width: 10.667rem;
  position: relative;
  background-color: #fff;
  border: 0.167rem solid #fff;
  top: -2.222rem;
}
.m17__media:before {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
.m17__media__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m17__media__image[src$=".svg"] {
  -o-object-fit: contain;
  object-fit: contain;
  padding: 30%;
  background-color: var(--primary-color);
}
.m17__title {
  margin-bottom: 0.71em;
}
.m17__button-wrap {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
@media (min-width: 810px) {
  .m17__button-wrap {
    margin-top: 0;
  }
}
.m17__button {
  padding-left: 0.9em;
  padding-right: 0.9em;
}
.m17__button svg {
  width: 2.222rem;
  height: 1.333rem;
  margin: 0;
}
.m17__button--prev {
  margin-right: -0.222rem;
}
.m17__button--prev svg {
  transform: rotate(180deg);
}
.m17__button[disabled] {
  opacity: 0.15;
  filter: grayscale(1);
  pointer-events: none;
  z-index: -1;
  background-color: var(--primary-color);
  color: #fff;
}
.m17__button.hide-button {
  opacity: 0;
}
.m18 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m18 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
.m18__filters {
  align-items: center;
}
@media (min-width: 768px) {
  .m18__filters {
    display: flex;
    margin: 1.667rem -1.389rem 3.333rem;
  }
}
.m18__filter-wrap {
  position: relative;
  max-width: 21.111rem;
}
@media (min-width: 768px) {
  .m18__filter-wrap {
    margin-bottom: 0;
    padding: 0 1.389rem;
  }
}
.m18__reset {
  background: none;
  border: 0;
  margin: 0 1.111rem;
  font-weight: 700;
  font-size: 1.111rem;
  color: var(--primary-color);
}
.m18__post-count {
  color: rgba(21, 20, 22, 0.5);
  font-size: 1rem;
  margin: 0.833rem 0;
  display: block;
}
.m18__vacancies {
  margin: 0.833rem -0.833rem;
  display: flex;
  flex-wrap: wrap;
}
.m18 .single-vacancy-item {
  padding: 0.833rem;
  width: 100%;
}
@media (min-width: 768px) {
  .m18 .single-vacancy-item {
    width: 50%;
  }
}
.m18 .single-vacancy-item__link {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
  padding: 2.222rem 6vw;
}
@media (min-width: 810px) {
  .m18 .single-vacancy-item__link {
    padding: 2.222rem 2.778rem;
  }
}
.m18 .single-vacancy-item__description {
  margin: 1.667rem 0;
}
.m18 .single-vacancy-item__description .read-more {
  font-weight: 700;
  text-decoration: underline;
}
.m18 .single-vacancy-item__tags {
  font-size: 1rem;
}
.m18 .single-vacancy-item__tags span:after {
  content: " | ";
}
.m18 .single-vacancy-item__tags span:last-child:after {
  content: none;
}
.m18 .pagination {
  justify-content: flex-start;
}
.m19 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.m19__link {
  margin-top: 1.667rem;
}
.m20 {
  margin-top: 2.6rem;
  margin-bottom: 2.6rem;
}
.m20--center {
  text-align: center;
}
.m20--right {
  text-align: right;
}
.m20__heading {
  margin-bottom: 0.63em;
}
.m20__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.m20--center .m20__list {
  justify-content: center;
}
.m20--right .m20__list {
  justify-content: flex-end;
}
.m20__item:not(:last-child) {
  margin-right: 0.667rem;
}
.m20__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0.222rem solid var(--primary-color);
  color: var(--primary-color);
  width: 4rem;
  height: 4rem;
  transition: color 0.25s ease, border-color 0.25s ease,
    background-color 0.25s ease;
}
.m20__link:focus,
.m20__link:hover {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.m20__link .svg-icon--icon-facebook {
  width: 0.944rem;
  height: 1.5rem;
}
.m20__link .svg-icon--icon-linkedin {
  width: 1.556rem;
  height: 1.722rem;
}
.m20__link .svg-icon--icon-twitter {
  width: 1.556rem;
  height: 1.556rem;
}
.m23 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3rem 0;
}
@media (min-width: 810px) {
  .m23 {
    padding: 4.6rem 0 4.4rem;
  }
}
@media (min-width: 810px) {
  .m23__container {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 810px) {
  .m23__content {
    width: 50%;
    max-width: 31.778rem;
  }
}
.m23 .gform_wrapper a:not([class]) {
  color: inherit;
}
@media (min-width: 810px) {
  .m23 .gform_wrapper {
    max-width: 27.778rem;
    width: 45%;
  }
}
@media (min-width: 810px) {
  .m23 .archive-container__filter-wrap,
  .m23 .gfield,
  .m23 .m18__filter-wrap {
    width: calc(100% - 0.95em);
  }
}
.m23 .gfield_radio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 810px) {
  .m23 .gfield_radio {
    justify-content: space-between;
  }
}
.m23 .gfield_radio input[type="radio"] + label {
  padding-top: 0;
}
.m23 input[type="email"],
.m23 input[type="number"],
.m23 input[type="password"],
.m23 input[type="search"],
.m23 input[type="tel"],
.m23 input[type="text"],
.m23 input[type="url"],
.m23 select,
.m23 textarea {
  border-color: #fff;
}
.m23 input[type="checkbox"]:checked + label:after,
.m23 input[type="checkbox"]:focus + label:before,
.m23 input[type="email"]:focus,
.m23 input[type="number"]:focus,
.m23 input[type="password"]:focus,
.m23 input[type="radio"]:checked + label:after,
.m23 input[type="radio"]:focus + label:before,
.m23 input[type="search"]:focus,
.m23 input[type="tel"]:focus,
.m23 input[type="text"]:focus,
.m23 input[type="url"]:focus,
.m23 select:focus,
.m23 textarea:focus {
  border-color: #e0e5e5;
}
.m23 input[type="checkbox"] + label:after,
.m23 input[type="checkbox"] + label:before,
.m23 input[type="radio"] + label:after,
.m23 input[type="radio"] + label:before {
  border: 0.167rem solid #fff;
  top: 0.167rem;
}
.m23 .gform_confirmation_message {
  color: inherit;
}
.m24 {
  margin: 2em auto;
  display: flex;
}
.m24--center {
  justify-content: center;
}
.m24--right {
  justify-content: flex-end;
}
.m27 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m27 {
    margin-top: 4rem;
    margin-bottom: 3.85rem;
  }
}
.m27 + .m16 {
  margin-top: 0;
}
.m27__container {
  display: flex;
  flex-direction: column;
}
.m27__sub-heading {
  margin: 0.15em 0 0;
}
.m27__terms {
  order: -1;
  color: rgba(21, 20, 22, 0.5);
  margin-bottom: 0.44em;
}
.m27__term:not(:last-child):after {
  content: "|";
  margin: 0 0 0 0.35em;
}
.m28 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3rem 0;
}
@media (min-width: 810px) {
  .m28 {
    padding: 3.45rem 0 5.25rem;
  }
}
.m28__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: relative;
  margin-bottom: 1.85em;
}
.m28__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
@media (min-width: 810px) {
  .m28__publications {
    margin: 0 -1.3rem;
  }
}
.m28 .single-publication-item {
  width: 100%;
}
@media (min-width: 810px) {
  .m28 .single-publication-item {
    width: calc(50% - 2.85rem);
  }
}
.m29 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m29 {
    margin-top: 5.2rem;
    margin-bottom: 3.85rem;
  }
}
.m29__container {
  display: flex;
  flex-direction: column;
}
.m29__terms {
  order: -1;
  color: rgba(21, 20, 22, 0.5);
  margin-bottom: 0.44em;
}
.m29__term:not(:last-child):after {
  content: "|";
  margin: 0 0 0 0.35em;
}
.m30 {
  margin-top: 5.15rem;
}
.m30__container {
  display: flex;
  flex-direction: column;
}
.m30__sub-heading {
  margin: 0.15em 0 0;
}
.m30__meta {
  order: -1;
  display: flex;
  align-items: center;
  margin-bottom: 1.25em;
}
.m30__tag {
  margin-right: 0.8em;
}
.m30__time {
  color: rgba(21, 20, 22, 0.5);
  font-size: 0.889rem;
}
.m30__media-wrap {
  min-height: 3.889rem;
  position: relative;
  margin: 1.7em 0 1.2em;
  display: flex;
  flex-direction: column;
}
.m30__downloads {
  order: 2;
  margin-top: 1em;
}
@media (min-width: 590px) {
  .m30__downloads {
    display: flex;
  }
}
@media (min-width: 810px) {
  .m30__downloads {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 100%;
    background-color: #fff;
    justify-content: flex-end;
  }
}
.m30__button {
  width: 100%;
  text-align: center;
}
@media (max-width: 589px) {
  .m30__button:focus,
  .m30__button:hover {
    border-color: var(--primary-color);
  }
}
@media (min-width: 590px) {
  .m30__button {
    flex-basis: 0;
    flex-grow: 1;
    width: auto;
  }
}
@media (min-width: 810px) {
  .m30__button {
    flex-grow: 0;
    flex-basis: auto;
  }
}
.m30__button:not(:first-child) {
  margin-top: 0.833rem;
}
@media (min-width: 590px) {
  .m30__button:not(:first-child) {
    margin: 0 0 0 1px;
  }
}
.m30__image {
  width: 100%;
  margin: 0 !important;
}
.m31__downloads {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.9rem 0 2rem;
}
.m31--left .m31__downloads {
  justify-content: flex-start;
}
.m31--right .m31__downloads {
  justify-content: flex-end;
}
.m31__button {
  width: 100%;
  margin-bottom: 0.833rem;
}
@media (min-width: 590px) {
  .m31__button {
    margin: 0 0.833rem 0.833rem 0;
    width: auto;
  }
}
.m31__button:focus,
.m31__button:hover {
  border-color: var(--primary-color);
}
.m32 {
  margin-top: 3rem;
  margin-bottom: 6rem;
}
@media (min-width: 810px) {
  .m32 {
    margin-top: 5rem;
    margin-bottom: 8rem;
  }
}
.m32__container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1040px) {
  .m32__container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.m32__content,
.m32__heading {
  max-width: 54.444rem;
}
.m32__intro,
.m32__introduction {
  margin-bottom: 3.9em;
}
.m32__map {
  position: relative;
  margin-top: 3em;
}
@media (min-width: 1040px) {
  .m32__map {
    order: -1;
    max-width: 35.222rem;
    width: 60%;
    margin: 0;
  }
}
.m32__map:before {
  padding-bottom: 100.9463722397%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  content: "";
}
.m32__map:after {
  z-index: -1;
  content: "";
  transform: translate(-4.778rem, 3.611rem);
  background-color: var(--primary-color);
}
.m32__map .gmap,
.m32__map:after {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.m32__map .gmap {
  z-index: 0;
}
@media (min-width: 1040px) {
  .m32__contact-info {
    width: 35%;
    max-width: 20.667rem;
  }
}
.m32__contact-info a:not(.m32__button) {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  border-bottom: 1px solid;
}
.m32__office-heading {
  margin-bottom: 0.48em;
}
.m32__address {
  font-style: normal;
  font-weight: 400;
}
.m32__phone {
  margin: 1.43em 0;
}
.m32__emails {
  list-style: none;
  padding: 0;
  margin: 0;
}
.m32__email-label {
  display: block;
}
.m32__button {
  margin-top: 2.15em;
}
.m32__select-wrapper {
  position: relative;
  margin-bottom: 1.9em;
}
.m32__select-wrapper:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 2.667rem;
  height: 100%;
  pointer-events: none;
  background-image: url(assets/svg/ico_select.svg);
  background-repeat: repeat-y;
  background-position: 100%;
}
.m32__select {
  width: 100%;
  font-size: 1rem;
  border: 0.167rem solid #e0e5e5;
  border-radius: 0;
  background-color: #fff;
  padding: 0.65em calc(2.5rem + 1em) 0.64em 1em;
  -webkit-appearance: none;
  appearance: none;
}
.m32__spinner {
  display: block;
  position: absolute;
  top: 50%;
  right: 3.222rem;
  margin-top: -0.556rem;
  opacity: 0;
  pointer-events: none;
  transform: opacity 0.25s ease;
}
body.is-loading .m32__spinner {
  opacity: 1;
}
body.is-loading .m32 .gmap,
body.is-loading .m32__address,
body.is-loading .m32__button {
  pointer-events: none;
  filter: grayscale(1);
  transition: 0.25s ease;
}
body.is-loading .m32__address,
body.is-loading .m32__button {
  opacity: 0.5;
}
body.gaming-theme .m32__select-wrapper:after {
  background-image: url(assets/svg/ico_select_purple.svg);
}
.m33 {
  margin-top: 4rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m33 {
    margin-top: 11rem;
    margin-bottom: 5.4rem;
  }
}
.m33__heading {
  margin-bottom: 1.23em;
}
@media (min-width: 560px) {
  .m33__offices {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.556rem;
  }
}
@media (min-width: 810px) {
  .m33__offices {
    margin: 0 -1.111rem;
  }
}
@media (min-width: 560px) {
  .m33__offices .single-office-item {
    margin: 0 0.556rem;
    width: calc(50% - 1.112rem);
  }
}
@media (min-width: 810px) {
  .m33__offices .single-office-item {
    margin: 0 1.111rem;
    width: calc(33.333% - 2.223rem);
  }
}
@media (min-width: 1080px) {
  .m33__offices .single-office-item {
    width: calc(25% - 2.223rem);
  }
}
.m35__header {
  background-color: var(--primary-color);
  padding: 4rem 0 5rem;
}
.m35__filters {
  position: relative;
  max-width: 43.333rem;
  width: 90%;
  margin: auto;
}
.m35__filters label {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 0.556rem;
}
.m35__search {
  position: relative;
  width: 100%;
}
.m35__search__input {
  width: 100%;
  border: 0;
  border-radius: 0;
  padding: 0.722rem 1.111rem;
}
.m35__search__icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 4.333rem;
  border-radius: 0;
  border: 0.222rem solid #fff;
  background-color: var(--primary-color);
  color: #fff;
}
.m35__search__icon:focus,
.m35__search__icon:hover {
  background-color: #fff;
  color: var(--primary-color);
}
.m35__search__icon svg {
  height: 1.444rem;
  width: 2.944rem;
  margin: auto;
}
.m35__reset {
  background: none;
  border: 0;
  margin-top: 0.556rem;
  font-weight: 700;
  font-size: 1.111rem;
  color: #fff;
}
@media (min-width: 970px) {
  .m35__reset {
    position: absolute;
    bottom: 0.722rem;
    right: -4.444rem;
  }
}
.m16--default .m35__container .m16__wrapper,
.m35__container .container,
.m35__container .m08__quote,
.m35__container .m16--default .m16__wrapper {
  max-width: 54.444rem;
  margin-top: 2em;
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .m16--default .m35__container .m16__wrapper,
  .m35__container .container,
  .m35__container .m08__quote,
  .m35__container .m16--default .m16__wrapper {
    margin-top: 5em;
    margin-bottom: 5em;
  }
}
.m35__sorting {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1rem;
  margin: 1.111rem 0;
}
.m35__sorting--input {
  padding-right: 1.056rem;
  position: relative;
}
.m35__sorting--input:after {
  content: "";
  position: absolute;
  top: 0.45rem;
  right: 0;
  width: 0;
  height: 0;
  border-left: 0.278rem solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0.278rem solid transparent;
  border-top: 0.444rem solid var(--primary-color);
}
.m35__sorting--sort {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.m35__single {
  display: block;
  justify-content: space-between;
  margin-bottom: 2.222rem;
}
@media (min-width: 810px) {
  .m35__single {
    display: flex;
  }
}
.m35__single--date {
  font-size: 1rem;
  color: rgba(21, 20, 22, 0.5);
}
.m35__single--title {
  margin: 0.833rem 0 0;
}
.m35__single--title a {
  color: #000;
  text-decoration: none;
}
.m35__single--excerpt p:first-child {
  margin-top: 0.2em;
}
.m35__single--link {
  color: var(--primary-color);
  text-decoration: none;
  margin: 0;
  font-size: 0.889rem;
}
@media (max-width: 809px) {
  .m35__single--link {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.m35__media {
  position: relative;
  width: 15rem;
  height: 14rem;
}
@media (max-width: 767px) {
  .m35__media {
    display: none;
  }
}
.m35__image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (min-width: 768px) {
  .m35__content {
    width: calc(100% - 17.778rem);
  }
}
.m35 .pagination {
  justify-content: flex-start;
}
.m36 {
  position: relative;
  padding: 3rem 0 5rem;
  color: #fff;
}
@media (min-width: 810px) {
  .m36 {
    min-height: 44.167rem;
  }
}
.m36:before {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}
.m16--default .m36 .m16__wrapper,
.m36 .container,
.m36 .m08__quote,
.m36 .m16--default .m16__wrapper {
  position: relative;
  z-index: 1;
}
.m36__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  font-style: normal;
  position: relative;
  margin-bottom: 0;
}
.m36__label:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + 1rem);
  width: 4rem;
  height: 0.111rem;
  background-color: currentColor;
}
.m36__heading {
  position: relative;
  z-index: 2;
}
.m36__heading-text {
  display: block;
}
.m36__button {
  margin-top: 1em;
}
.m36__media {
  z-index: -1;
}
.m36__image,
.m36__media {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.m36__image {
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m37 {
  background-color: var(--primary-color);
  color: #fff;
  padding: 3rem 0;
}
@media (min-width: 810px) {
  .m37 {
    padding: 3.85rem 0 4.15rem;
  }
}
@media (min-width: 810px) {
  .m37__container {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 810px) {
  .m37__content {
    width: 50%;
    max-width: 31.778rem;
    margin-top: 0.9em;
  }
}
@media (min-width: 810px) {
  .m37 .gform_wrapper {
    max-width: 27.778rem;
    width: 45%;
  }
}
.m37 .gform_wrapper a:not([class]) {
  color: inherit;
}
@media (min-width: 810px) {
  .m37 .gfield_radio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 810px) {
  .m37 .gfield_radio li {
    width: calc(50% - 0.278rem);
    margin-right: 0.556rem;
  }
}
@media (min-width: 810px) {
  .m37 .gfield_radio li:nth-child(2n) {
    margin-right: 0;
  }
}
@media (min-width: 810px) {
  .m37 .archive-container__filter-wrap,
  .m37 .gfield,
  .m37 .m18__filter-wrap {
    width: calc(100% - 0.95em);
  }
}
.m37 input[type="email"],
.m37 input[type="number"],
.m37 input[type="password"],
.m37 input[type="search"],
.m37 input[type="tel"],
.m37 input[type="text"],
.m37 input[type="url"],
.m37 select,
.m37 textarea {
  border-color: #fff;
}
.m37 input[type="email"]:focus,
.m37 input[type="number"]:focus,
.m37 input[type="password"]:focus,
.m37 input[type="search"]:focus,
.m37 input[type="tel"]:focus,
.m37 input[type="text"]:focus,
.m37 input[type="url"]:focus,
.m37 select:focus,
.m37 textarea:focus {
  border-color: #e0e5e5;
}
.m37 input[type="checkbox"]:checked + label:after,
.m37 input[type="checkbox"]:focus + label:before,
.m37 input[type="radio"]:checked + label:after,
.m37 input[type="radio"]:focus + label:before {
  border-color: #fff;
}
.m37 input[type="checkbox"] + label:after,
.m37 input[type="checkbox"] + label:before,
.m37 input[type="radio"] + label:after,
.m37 input[type="radio"] + label:before {
  top: 0.65em;
  border: 0.167rem solid #fff;
}
.m38 {
  margin: 1.667rem 0;
}
.m38__content-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
.m38__content-wrapper iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.m39 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.m39__heading {
  margin-bottom: 0.76em;
}
.m39__content-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8vw 0;
  background-color: var(--primary-color);
}
@media (min-width: 810px) {
  .m39__content-wrap {
    padding: 6.6rem 0;
  }
}
.m39__background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.m39__container {
  position: relative;
}
.m39__container-inner {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
.m39__container-inner > * {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.m40 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m40 {
    margin-top: 8.5rem;
    margin-bottom: 8.7rem;
  }
}
.m40__container {
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}
@media (min-width: 810px) {
  .m40__container {
    display: flex;
  }
}
.m40__heading {
  margin-bottom: 0.1em;
}
.m40__tag {
  font-size: 0.889rem;
}
.m40__button-wrap {
  margin-top: 1.7em;
}
.m40__content-wrap {
  padding: 8vw 5vw;
}
@media (min-width: 810px) {
  .m40__content-wrap {
    padding: 4.35rem 3.9rem 5.4rem;
    width: calc(50% + 2rem);
    flex-grow: 1;
  }
}
@media (min-width: 810px) {
  .m40__media {
    width: calc(50% - 2rem);
    position: relative;
  }
}
.m40__media__image {
  width: 100%;
  margin: 0 !important;
}
@media (min-width: 810px) {
  .m40__media__image {
    min-height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.m42 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.m42__heading {
  margin-bottom: 0.58em;
  text-align: center;
}
.m42__links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #8a8a8b;
}
@media (min-width: 650px) {
  .m42__links {
    justify-content: space-between;
  }
}
.m42__button {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0.5em;
}
@media (min-width: 650px) {
  .m42__button {
    margin: 1.2rem 0;
  }
}
.m42__ident {
  display: none;
}
@media (min-width: 650px) {
  .m42__ident {
    display: block;
    font-size: 0.8rem;
    margin: 0 0.5em;
  }
}
@media (min-width: 650px) {
  .m42__ident:nth-of-type(5n) {
    display: none;
  }
}
.m42__break {
  display: none;
}
@media (min-width: 650px) {
  .m42__break {
    width: 100%;
    display: block;
  }
}
.m44 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media (min-width: 810px) {
  .m44 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
}
.m44__heading {
  margin-bottom: 0.74em;
}
@media (min-width: 700px) {
  .m44__takeouts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.5rem;
  }
}
.m44__takeouts .single-takeout-item {
  margin-bottom: 1rem;
  width: 100%;
}
@media (min-width: 700px) {
  .m44__takeouts .single-takeout-item {
    margin: 0 0.5rem 1rem;
    width: calc(50% - 1rem);
  }
}
@media (min-width: 940px) {
  .m44__takeouts .single-takeout-item {
    width: calc(33.333% - 1rem);
  }
}
.m45 {
  position: relative;
}
.m45__background {
  width: 100%;
  height: auto;
  max-height: 100%;
}
@media (min-width: 810px) {
  .m45__downloads {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }
}
.m45__button {
  width: 100%;
  position: relative;
}
@media (min-width: 810px) {
  .m45__button {
    width: auto;
  }
}
.m45__button:last-child:before {
  content: "";
  background-color: #fff;
  display: block;
  position: absolute;
  top: -0.222rem;
  left: -0.222rem;
  width: calc(100% + 0.444rem);
  height: 1px;
}
@media (min-width: 810px) {
  .m45__button:last-child:before {
    width: 1px;
    height: calc(100% + 0.444rem);
  }
}
.archive-container__heading {
  margin-bottom: 2.222rem;
}
.archive-container__filters {
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  .archive-container__filters {
    display: flex;
    margin: 2.333rem -0.75rem 3.333rem;
  }
}
.archive-container__filter-wrap {
  position: relative;
}
@media (min-width: 768px) {
  .archive-container__filter-wrap {
    margin-bottom: 0;
    padding: 0 0.75rem;
    max-width: 15.3rem;
  }
}
.archive-container__reset {
  background: none;
  border: 0;
  margin: 0 0 1em;
  font-weight: 700;
  font-size: 1.111rem;
  color: var(--primary-color);
}
@media (min-width: 768px) {
  .archive-container__reset {
    margin: 0 1.111rem;
  }
}
.archive-container__spinner {
  position: absolute;
  bottom: 1.167rem;
  right: 0;
  opacity: 0;
  transition: opacity 0.15s ease;
}
@media (min-width: 768px) {
  .archive-container__spinner {
    bottom: 0.778rem;
    right: -0.556rem;
  }
}
.terra--ajax .archive-container__spinner {
  opacity: 1;
}
.archive-container__items > .single-case-study-item,
.archive-container__items > .single-insight-item {
  width: 100%;
}
.archive-container__items-grid {
  margin-top: 2.222rem;
  margin-bottom: 2.222rem;
}
.archive-container .single-case-study-item__excerpt,
.archive-container .single-insight-item__excerpt {
  display: none;
}
.terra .no-results,
.terra .single-case-study-item,
.terra .single-insight-item {
  transition: 0.6s ease;
}
.terra--ajax .no-results,
.terra--ajax .single-case-study-item,
.terra--ajax .single-insight-item {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(1);
}
.terra--done .no-results,
.terra--done .single-case-study-item,
.terra--done .single-insight-item {
  opacity: 0;
  animation: fade 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.wp-block-columns {
  position: relative;
}
@media (min-width: 810px) {
  .wp-block-columns {
    margin-left: -8vw;
    margin-right: -8vw;
  }
}
.wp-block-columns .twitter-tweet {
  border-radius: 0.667rem;
  box-shadow: 0 0.111rem 1.333rem 0 #e0e5e5;
}
figure.wp-block-image {
  margin: 6rem 0;
}
@media (min-width: 1040px) {
  figure.wp-block-image {
    margin: 6rem -7.5vw;
  }
}
figure.wp-block-image figcaption {
  font-size: 0.889rem;
  color: rgba(21, 20, 22, 0.5);
}
figure.wp-block-video {
  margin: 6rem 0;
}
figure.wp-block-video figcaption {
  font-size: 0.889rem;
  color: rgba(21, 20, 22, 0.5);
}
.block-editor-block-list__layout .wp-block {
  margin-left: auto !important;
  margin-right: auto !important;
}
.author-card {
  margin: 0.85em 0;
  display: flex;
  align-items: center;
  padding-left: 1.667rem;
}
@media (max-width: 809px) {
  .author-card {
    padding-left: 0;
    font-size: 0.889rem;
    line-height: 1.2;
    margin: 0.5em 0;
  }
}
.author-card__media {
  overflow: hidden;
  position: relative;
  margin-right: 0.5em;
  border-radius: 100%;
  width: 4.167rem;
  height: 4.167rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 810px) {
  .author-card__media {
    width: 5.778rem;
    height: 5.778rem;
  }
}
.author-card__media .avatar {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0 !important;
}
.author-card__linkedin {
  background-color: #151416;
  border: 0.167rem solid #fff;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 0.444rem;
  width: 2rem;
  height: 2rem;
  left: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 810px) {
  .author-card__linkedin {
    width: 3.111rem;
    height: 3.111rem;
    left: -1.667rem;
  }
}
.author-card__linkedin svg {
  width: 1.111rem;
  height: 1.278rem;
}
.author-card__name {
  display: block;
  font-weight: 700;
}
.single-office-item {
  border-top: 0.222rem solid var(--primary-color);
  font-style: normal;
  font-weight: 400;
  padding: 1.2em 0 2.5em;
}
.single-office-item__heading {
  margin-bottom: 0.2em;
}
.single-office-item a {
  color: var(--primary-color);
  font-weight: 700;
  text-decoration: none;
  border-bottom: 1px solid;
}
.single-office-item p {
  margin: 0;
}
.single-insight-item__link {
  text-decoration: none;
  color: #151416;
  display: block;
}
.single-insight-item__header {
  position: relative;
  margin-bottom: 0.85em;
}
.single-insight-item__tag-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}
.single-insight-item__image {
  width: 100%;
}
.single-insight-item__heading {
  margin-bottom: 0.15em;
}
.single-insight-item__excerpt {
  margin: 1.111rem 0;
}
.single-insight-item__meta {
  font-size: 0.889rem;
  color: rgba(21, 20, 22, 0.5);
}
.single-case-study-item__link {
  text-decoration: none;
  color: #151416;
  display: block;
}
.single-case-study-item__header {
  position: relative;
  margin-bottom: 0.85em;
}
.single-case-study-item__tag {
  position: absolute;
  bottom: 0;
  left: 0;
}
.single-case-study-item__image {
  width: 100%;
}
.single-case-study-item__heading {
  margin-bottom: 0.15em;
}
.single-case-study-item__excerpt {
  margin: 1.111rem 0;
}
.single-case-study-item__excerpt .read-more {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 700;
}
.single-case-study-item__meta {
  font-size: 0.889rem;
  color: rgba(21, 20, 22, 0.5);
}
.single-case-study-item__term:after {
  content: " | ";
}
.single-case-study-item__term:last-child:after {
  content: none;
}
.single-publication-item {
  font-size: 0.889rem;
  line-height: 1.222rem;
}
.single-publication-item__media__image {
  width: 100%;
}
.single-publication-item__media + .single-publication-item__heading {
  margin-top: 1.1em;
}
.single-publication-item__heading {
  font-weight: 700;
}
.single-publication-item__caption,
.single-publication-item__heading {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
.single-publication-item__link {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid;
}
.single-publication-item__link svg {
  display: none;
}
.single-takeout-item {
  background-color: var(--primary-color);
  color: #fff;
  padding: 2.65rem 2rem 1.5rem;
  text-align: center;
}
.single-takeout-item__number {
  width: 5.389rem;
  height: 5.389rem;
  background-color: #fff;
  border: 4px solid #fff;
  margin: 0 auto 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.444rem;
  line-height: 1.2;
  font-style: italic;
  font-weight: 700;
  color: var(--primary-color);
}
.single-takeout-item__link {
  color: inherit;
  font-weight: 700;
  display: inline-block;
  margin: 0 auto 10px;
}
.single-post .entry-content.post .wp-block-image,
.single-post .entry-content.post img:not(.tag__icon) {
  margin: 1.111rem auto;
  width: 100%;
}
.single-post .entry-content.post > h1:not(:first-child),
.single-post .entry-content.post > h2:not(:first-child),
.single-post .entry-content.post > h3:not(:first-child),
.single-post .entry-content.post > h4:not(:first-child),
.single-post .entry-content.post > h5:not(:first-child),
.single-post .entry-content.post > h6:not(:first-child) {
  margin: 1em 0 0;
}
.single-post .entry-content.post .digital-report-slideshare-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
  display: block;
}
.single-post .entry-content.post .digital-report-slideshare-wrapper > * {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.single-post .entry-content.post > iframe {
  width: 100%;
  min-height: 350px;
}
.single .gated-content {
  position: relative;
  margin-bottom: 2em;
}
.single .gated-content:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 200px;
  background: #fff;
  background: linear-gradient(0deg, #fff 30%, hsla(0, 0%, 100%, 0));
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=style.css.map */
